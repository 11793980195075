import {BEGINNERS_SECTION, sectionIds, STAYTUNED_SECTION} from '../../../constants/AppConstants'
import {useLocation} from 'react-router-dom'
import FeedbackSection from './feedbackSection/FeedbackSection'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import InterestListButton from '../../pages/about/components/InterestListButton'
import React, {FormEvent, useState, useRef, useEffect} from 'react'
import useFocus from '../hooks/useFocus'
import {motion, useTransform, useScroll} from 'framer-motion'
import {addToWaitlist} from './core/_request'
import ReactGA from 'react-ga4'
import {ChartExperiment} from '../ABTest/_ABTestConfig'
import {emitter} from '@marvelapp/react-ab-test'
import {Button} from 'react-bootstrap'
import {toast} from 'react-toastify'

interface BeginnersSectionProps {
  subHeading: string
  paragraph: string
  secondParagraph: string
}

const BeginnersSection = (props: BeginnersSectionProps) => {
  const location = useLocation()
  const isAboutPage = location.pathname.includes('about')
  const currentHostname = window.location.hostname
  const currentPathname = window.location.pathname
  const {subHeading, paragraph, secondParagraph} = props
    const [showInput, setShowInput] = useState(false)
    const {inputRef, focusInput} = useFocus()

    useEffect(() => {
      if (showInput) {
        focusInput()
      }
    }, [showInput, focusInput])

    // setting toastify success/error
    const notifyError = (msg: string) => toast.error(msg)

    const [joinWaitlistToggle, setJoinWaitlistToggle] = useState(0)
    const [waitListEmail, setWaitListEmail] = useState('')
    const [isFinancePro, setIsFinancePro] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const toggleWaitlistInput = () => {
      setShowInput(true)
      setJoinWaitlistToggle(1)
    }
    const targetRef = useRef(null)
    const {scrollYProgress} = useScroll({
      target: targetRef,
      offset: ['start end', 'end start'],
    })
    const isProduction = process.env.NODE_ENV === 'production'
    const cloudTopX = useTransform(scrollYProgress, [0, 1], ['-30%', '20%'])
    const cloudMidX = useTransform(scrollYProgress, [0, 0.6], ['-30%', '30%'])
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      // add user to waitlist
      setIsLoading(true)
      try {
        await addToWaitlist(waitListEmail, isFinancePro)
        setIsLoading(false)
        setJoinWaitlistToggle(2)
      } catch (error: any) {
        if (error?.response?.data) {
          notifyError(error?.response?.data?.message)
        } else {
          notifyError('Error occurred during the request.')
        }
        setIsLoading(false)
      }

      // Only track the GA event in production
      if (isProduction) {
        ReactGA.event({
          category: ChartExperiment.name,
          action: emitter.getActiveVariant(ChartExperiment.name),
        })
      }
    }
  const handleWaitlistToggleUI = (joinWaitlistToggle: number) => {
    switch (joinWaitlistToggle) {
      case 1:
        return (
          <>
            <form
              className='d-flex align-items-center flex-column pt-2'
              onSubmit={handleSubmit}
            >
              <div className='col-sm-6 col-12 d-flex justify-content-center align-items-center waitlist-input'>
                <input
                  ref={inputRef}
                  type='email'
                  className='form-control form-control-lg form-control-solid py-2'
                  placeholder='Enter your email'
                  required
                  value={waitListEmail}
                  onChange={(e) => setWaitListEmail(e.target.value)}
                />
              </div>
              <label className='mt-5'>
                <input
                  type='checkbox'
                  checked={isFinancePro}
                  onChange={() => setIsFinancePro(!isFinancePro)}
                />
                I'm also a finance pro
              </label>
              <Button
                type='submit'
                className='custom-secondary-button my-6 border-0'
                disabled={isLoading}
              >
                {!isLoading && <span className='indicator-label'>SUBMIT</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </form>
          </>
        )
      case 2:
        return (
          <div className='mb-8 stay-tuned-join'>
            <p className='sub-heading-small primary-text'>THANK YOU</p>
          </div>
        )
      default:
        return (
          <div className='mb-8 stay-tuned-join'>
            <Button className='custom-secondary-button' onClick={toggleWaitlistInput}>
              {BEGINNERS_SECTION.INTEREST_LIST}
            </Button>
          </div>
        )
    }
  }
  return (
    <div
      className={
        sectionIdToShowInWebViewer !== sectionIds[7] && showInWebViewer
          ? 'd-none overflow-hidden'
          : 'overflow-hidden'
      }
      style={{width: '100%'}}
    >
      <section className='position-relative z-index-1  pt-5 pt-lg-15' id={sectionIds[7]}>
        <h1 className='my-2 mb-4 mb-md-5 mb-lg-10 text-center'>
          {' '}
          {isAboutPage ? BEGINNERS_SECTION.WE_DO_FOR_ABOUT_PAGE : BEGINNERS_SECTION.WE_DO}{' '}
        </h1>
        <div className='px-7 px-md-10 px-lg-20'>
          <p className='text-center mb-1'>{subHeading}</p>
          <br></br>
          <div className='d-flex justify-content-center align-items-center'>
            <p className='text-center mission-text'>{paragraph}</p>
          </div>
          {currentPathname === '/about' ? (
            <div className='d-flex justify-content-center align-items-center'>
              <p className='text-center mission-text mb-0'>{secondParagraph}</p>
            </div>
          ) : null}

          <img
            className='cloud-big d-none d-md-block'
            src={BEGINNERS_SECTION.IMAGES.CLOUD_LARGE}
            alt='cloud-big'
          />
          <img
            className='cloud-small'
            src={BEGINNERS_SECTION.IMAGES.CLOUD_SMALL}
            alt='cloud-small'
          />
          {handleWaitlistToggleUI(joinWaitlistToggle)}
          <div className='row m-0 mt-7'>
            <div className='col-lg-5'>
              {/* {currentPathname !== '/about' && (
                <p className='text-center text-xl-start'>
                  {currentHostname === 'vividli.ai'
                    ? BEGINNERS_SECTION.VIVIDLI_MONOLITH_HELP
                    : BEGINNERS_SECTION.MONOLITH_HELP}
                </p>
              )} */}
            </div>
            {/* our values section desktop view */}
            <div className='d-none d-lg-block col-lg-7 our-values-container'>
              <h2 className='our-values-text position-absolute z-index-1'>
                {BEGINNERS_SECTION.OUR_VALUES}
              </h2>
              <img
                className={`position-absolute watering-plants-img ${
                  currentPathname === '/about' ? 'mt-20' : ''
                }`}
                src={BEGINNERS_SECTION.IMAGES.WATERING_PLANTS}
                alt='cloud-big'
              />
              <img
                className={`position-absolute our-values-img ${
                  currentPathname === '/about' ? 'mt-20' : ''
                }`}
                src={BEGINNERS_SECTION.IMAGES.VALUES_BAG}
                alt='cloud-big'
              />
            </div>
          </div>
          {/* our values section mobile view */}
          <div className='d-flex d-lg-none flex-column justify-content-center align-items-center'>
            <img
              className='watering-plants-img'
              src={BEGINNERS_SECTION.IMAGES.WATERING_PLANTS}
              alt='cloud-big'
            />
            <h2 className='our-values-text text-center mt-4 mt-lg-6'>
              {BEGINNERS_SECTION.OUR_VALUES}
            </h2>
            <img
              className='our-values-img'
              src={BEGINNERS_SECTION.IMAGES.VALUES_BAG}
              alt='cloud-big'
            />
          </div>

          {/* feedback section */}
          {currentPathname !== '/about' && (
            <div className={showInWebViewer ? 'd-none' : ''}>
              <FeedbackSection sectionName='beginners section' />
            </div>
          )}
        </div>
      </section>
      {window.location.pathname === '/about' && <InterestListButton />}
    </div>
  )
}

export default BeginnersSection
