import {TYPES_OF_ROR} from '../../../../constants/AppConstants'
import {WorthData} from '../../advisor/components/_model'
import {
  calculateARQAppreciation,
  calculateARQAppreciationWithEquity,
  calculateFutureValue,
  calculatePropertyWorthPercentages_New,
} from './CommonCalculatorFunctions'
import {
  BottomLineModel,
  CompoundingGrowth,
  InvestmentChartProps,
  InvestmentTableModel,
} from './_model'
import {
  calculateAfterManagementFeePercentages,
  calculatePropertyWorthPercentages,
} from './CommonCalculatorFunctions'

const pathsToCheck = ['arq/home', 'advisor-demo1/home', 'advisor-demo2/home', 'waystone/home']
const isArqInURL = pathsToCheck.some((path) => window.location.pathname.includes(path))

export const generateChartData = (
  initialDeposit: number,
  contributions: number,
  contributionFrequency: number,
  numberOfYears: number,
  compoundingGrowth: CompoundingGrowth[],
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  management_fee: number,
  isManagementFeeApplied: boolean = true,
  rollOver: boolean,
  customerActual: boolean,
  newStartingAmount: number,
  ARQ_Property_End_Value: number
): InvestmentChartProps[] => {
  initialDeposit = isArqInURL ? (rollOver ? initialDeposit : newStartingAmount) : initialDeposit
  initialDeposit = initialDeposit ? initialDeposit : 0
  const chartData = compoundingGrowth.map((rate) => {
    const Equity = calculatePropertyWorthPercentages_New(
      ARQ_Flip_Gain,
      ARQ_property_worth,
      initialDeposit,
      rollOver,
      newStartingAmount
    )
    // ARQ appreciation with equity
    const calculatedARQAppreciationWithEquity = calculateARQAppreciationWithEquity(
      ARQ_Flip_Gain,
      ARQ_property_worth,
      initialDeposit,
      rollOver,
      newStartingAmount,
      ARQ_Property_End_Value,
      ARQ_property_worth
    )

    // ARQ appreciation
    const originalARQAppreciation = calculateARQAppreciation(
      ARQ_Property_End_Value,
      ARQ_property_worth
    )

    // for flip
    const flipGainWithPropertyWorthPercentage = customerActual
      ? ARQ_Flip_Gain
      : (initialDeposit / ARQ_property_worth) * ARQ_Flip_Gain
    // for rental
    const rentalIncomeWithPropertyWorthPercentage = ARQ_rental_income * Equity

    // for appreciation
    const appreciationWithPropertyWorthPercentage = originalARQAppreciation * Equity

    // Calculate values after subtracting management fees
    // for flip
    const flipGainAfterManagementFee = calculateAfterManagementFeePercentages(
      flipGainWithPropertyWorthPercentage,
      isManagementFeeApplied ? 100 - management_fee : 0
    )
    // for rental
    const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
      rentalIncomeWithPropertyWorthPercentage,
      isManagementFeeApplied ? 100 - management_fee : 0
    )
    // for appreciation
    const appreciationAfterManagementFee = calculateAfterManagementFeePercentages(
      calculatedARQAppreciationWithEquity,
      isManagementFeeApplied ? 100 - management_fee : 0
    )
    let futureValue = 0
    let gains = 0
    let customROR: number | null = null
    let rentalIncome = 0
    let futureValueWithoutContributions = 0
    let isLegacy =
      rate.type !== TYPES_OF_ROR.TYPE_SIMPLE &&
      (rate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL || rate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP)
        ? true
        : false
    if (rate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
      futureValue = initialDeposit + flipGainWithPropertyWorthPercentage
      customROR = initialDeposit > 0 ? Math.abs(initialDeposit - futureValue) / initialDeposit : 0
      futureValueWithoutContributions = futureValue
    } else if (rate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL) {
      rentalIncome =
        rentalIncomeWithPropertyWorthPercentage * ARQ_rental_contribution * numberOfYears
      futureValue =
        initialDeposit +
        (flipGainWithPropertyWorthPercentage +
          rentalIncome +
          appreciationWithPropertyWorthPercentage)
      gains =
        flipGainWithPropertyWorthPercentage + rentalIncome + appreciationWithPropertyWorthPercentage
      customROR =
        initialDeposit > 0 ? gains / initialDeposit / numberOfYears : gains / numberOfYears
      futureValueWithoutContributions = futureValue
    } else if (
      rate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT ||
      rate.type === TYPES_OF_ROR.TYPE_RENTAL_SALE
    ) {
      rentalIncome = rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
      futureValue =
        initialDeposit +
        (flipGainAfterManagementFee +
          rentalIncomeAfterManagementFee +
          appreciationAfterManagementFee)
      gains = flipGainAfterManagementFee + rentalIncome + appreciationAfterManagementFee
      customROR =
        initialDeposit > 0 ? gains / initialDeposit / numberOfYears : gains / numberOfYears
      futureValueWithoutContributions = futureValue
    } else if (
      rate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT ||
      rate.type === TYPES_OF_ROR.TYPE_RENTAL_INCOME_ONLY
    ) {
      rentalIncome = rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
      futureValue = initialDeposit + rentalIncome

      gains = rentalIncome
      customROR =
        initialDeposit > 0 ? gains / initialDeposit / numberOfYears : gains / numberOfYears
      futureValueWithoutContributions = futureValue
    } else if (rate.type === TYPES_OF_ROR.TYPE_SIMPLE && rate.value !== 0) {
      futureValue = calculateFutureValue(
        initialDeposit,
        rate.value,
        contributionFrequency,
        numberOfYears,
        contributions
      )

      futureValueWithoutContributions =
        futureValue - contributions * contributionFrequency * numberOfYears - initialDeposit
    }

    return {
      id: rate.id,
      value: rate.value,
      label: rate.label,
      customLabel: rate.customLabel,
      riskVoltality: rate.riskVoltality,
      labelColor: rate.color,
      futureValue: parseFloat(futureValue.toFixed(2)),
      contributions: contributions * contributionFrequency * numberOfYears,
      initialDeposit,
      isLoss: rate.value < 0 && customROR === null,
      numberOfYears: numberOfYears,
      futureValueWithoutContributions,
      ARQ_Flip_Gain: isLegacy ? flipGainWithPropertyWorthPercentage : flipGainAfterManagementFee,
      type: rate.type,
      ARQ_rental_income: rentalIncome,
      ARQ_rental_contribution,
      ARQ_appreciation: isLegacy
        ? appreciationWithPropertyWorthPercentage
        : appreciationAfterManagementFee,
      ARQ_property_worth,
      customROR: customROR && customROR * 100,
      originalARQValues: {
        proportionValue: ARQ_property_worth !== 0 ? (initialDeposit / ARQ_property_worth) * 100 : 0,
        fixAndFlip: ARQ_Flip_Gain,
        rental: ARQ_rental_income * ARQ_rental_contribution * numberOfYears,
        appreciation: ARQ_appreciation,
        management_fee: management_fee,
      },
    }
  })
  return chartData
}

// This function calculates the future value of an investment based on various parameters.
export const calculateAdvisorFutureValue = (
  initialDeposit: number,
  selectedRate: CompoundingGrowth,
  contributions: number,
  contributionFrequency: number,
  numberOfYears: number,
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  management_fee: number
): WorthData => {
  // Calculate percentages based on property worth for flip gain, rental income, and appreciation
  const flipGainWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
    ARQ_Flip_Gain,
    ARQ_property_worth,
    initialDeposit
  )
  const rentalIncomeWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
    ARQ_rental_income,
    ARQ_property_worth,
    initialDeposit
  )
  const appreciationWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
    ARQ_appreciation,
    ARQ_property_worth,
    initialDeposit
  )

  // Calculate values after subtracting management fees
  const flipGainAfterManagementFee = calculateAfterManagementFeePercentages(
    flipGainWithPropertyWorthPercentage,
    management_fee
  )

  const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
    rentalIncomeWithPropertyWorthPercentage,
    management_fee
  )

  const appreciationAfterManagementFee = calculateAfterManagementFeePercentages(
    appreciationWithPropertyWorthPercentage,
    management_fee
  )

  let futureValue = 0
  let rentalIncome = 0
  // Determine the type of compounding growth rate selected
  if (selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
    // Calculate future value for ARQ_FLIP scenario
    futureValue = initialDeposit + flipGainWithPropertyWorthPercentage
    futureValue += flipGainWithPropertyWorthPercentage + appreciationWithPropertyWorthPercentage
  } else if (selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL) {
    // Calculate future value for ARQ_RENTAL scenario
    rentalIncome = rentalIncomeWithPropertyWorthPercentage * ARQ_rental_contribution * numberOfYears
    futureValue =
      initialDeposit +
      (flipGainWithPropertyWorthPercentage + rentalIncome + appreciationWithPropertyWorthPercentage)
  } else if (selectedRate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT) {
    // Calculate future value for AFTER_EXIT scenario
    rentalIncome = rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
    futureValue =
      initialDeposit + (flipGainAfterManagementFee + rentalIncome + appreciationAfterManagementFee)
  } else if (selectedRate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT) {
    // Calculate future value for BEFORE_EXIT scenario
    rentalIncome = rentalIncomeAfterManagementFee * ARQ_rental_contribution * numberOfYears
    futureValue = initialDeposit + rentalIncome
  } else {
    // Calculate future value using a different compounding rate
    futureValue = calculateFutureValue(
      initialDeposit,
      selectedRate.value,
      contributionFrequency,
      numberOfYears,
      contributions
    )
  }

  return {
    futureValue,
    initialDeposit,
    appreciation:
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP ||
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL
        ? appreciationWithPropertyWorthPercentage
        : appreciationAfterManagementFee,
    fixAndFlip:
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP ||
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL
        ? flipGainWithPropertyWorthPercentage
        : flipGainAfterManagementFee,
    rentalIncome,
    originalARQValues: {
      proportionValue: ARQ_property_worth !== 0 ? (initialDeposit / ARQ_property_worth) * 100 : 0,
      fixAndFlip: ARQ_Flip_Gain,
      rental: ARQ_rental_income * ARQ_rental_contribution * numberOfYears,
      appreciation: ARQ_appreciation,
      management_fee: management_fee,
    },
  }
}

const calculateSimpleInvestmentDataForPeriod = (
  initialDeposit: number,
  selectedRate: CompoundingGrowth,
  contributions: number,
  contributionFrequency: number,
  numberOfYears: number,
  period: number,
  frequency: number,
  previousCumultiveGain: number
): InvestmentTableModel => {
  const futureValue = calculateFutureValue(
    initialDeposit,
    selectedRate.value,
    contributionFrequency,
    period,
    contributions
  )
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  let cumulativeContributions = 0
  let previousFutureValue = 0

  const contributionsThisPeriod = contributions * frequency
  cumulativeContributions = contributionsThisPeriod * period

  const cummulativeGain =
    period === 1
      ? futureValue - initialDeposit - contributionsThisPeriod
      : futureValue - previousFutureValue - period * contributionsThisPeriod - initialDeposit

  let gainThisPeriod = cummulativeGain - previousCumultiveGain
  const monthIndex = (currentMonth + Math.floor(period * 12)) % 12
  const year = currentYear + Math.floor(period)
  const currentDateForMonth = new Date(year, monthIndex, 1)
  const monthName = currentDateForMonth.toLocaleString('default', {month: 'long'})
  const totalCashOnCash = gainThisPeriod / initialDeposit
  previousFutureValue = futureValue
  const roi =
    (futureValue - (initialDeposit + cumulativeContributions)) /
    (initialDeposit + cumulativeContributions)

  return {
    yearOrMonth: numberOfYears === 1 ? monthName : year.toString(),
    initialDeposit: +initialDeposit.toFixed(2),
    contributionsThisPeriod: +contributionsThisPeriod.toFixed(2),
    cumulativeContributions: +cumulativeContributions.toFixed(2),
    gainThisPeriod: selectedRate.value !== 0 ? +gainThisPeriod.toFixed(2) : 0,
    cumulativeGains: selectedRate.value !== 0 ? +cummulativeGain.toFixed(2) : 0,
    futureValue: selectedRate.value !== 0 ? +futureValue.toFixed(2) : 0,
    gain:
      selectedRate.value !== 0
        ? +(futureValue - initialDeposit - cumulativeContributions).toFixed(2)
        : 0,
    isMonthly: numberOfYears === 1,
    selectedRate: selectedRate.dropdownLabel || '',
    customROR: null,
    ROR: (+roi * 100).toFixed(2) + '%',
    flipGain: null,
    rentalIncome: null,
    appreciation: null,
    cashOnCash: null,
    totalCashOnCash: +(totalCashOnCash * 100).toFixed(2),
    isLoss: selectedRate.value < 0,
    originalARQValues: {
      proportionValue: 0,
      fixAndFlip: 0,
      rental: 0,
      appreciation: 0,
      management_fee: 0,
    },
  }
}

const calculateRentalProfits = (
  initialDeposit: number,
  numberOfYears: number,
  selectedRate: CompoundingGrowth,
  flipGain: number,
  propertyWorth: number,
  appreciation: number,
  rentalFrequency: number,
  rentalIncome: number,
  management_fee: number,
  period: number,
  isLegacy: boolean,
  isForTable: boolean,
  rollover: boolean,
  newStartingAmount: number,
  propertyWorthEndValue: number,
  customerActual: boolean,
  isManagementFeeApplied: boolean
): InvestmentTableModel => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  const Equity = calculatePropertyWorthPercentages_New(
    flipGain,
    propertyWorth,
    initialDeposit,
    rollover,
    newStartingAmount
  )
  // ARQ appreciation with equity
  const calculatedARQAppreciationWithEquity = calculateARQAppreciationWithEquity(
    flipGain,
    propertyWorth,
    initialDeposit,
    rollover,
    newStartingAmount,
    propertyWorthEndValue,
    propertyWorth
  )

  // ARQ appreciation
  const originalARQAppreciation = calculateARQAppreciation(propertyWorthEndValue, propertyWorth)

  // for flip
  const flipGainWithPropertyWorthPercentage = customerActual
    ? flipGain
    : (initialDeposit / propertyWorth) * flipGain
  // for rental
  const rentalIncomeWithPropertyWorthPercentage = rentalIncome * Equity

  // for appreciation
  const appreciationWithPropertyWorthPercentage = originalARQAppreciation * Equity

  // Calculate values after subtracting management fees
  // for flip
  const flipGainAfterManagementFee = calculateAfterManagementFeePercentages(
    flipGainWithPropertyWorthPercentage,
    isManagementFeeApplied ? 100 - management_fee : 0
  )
  // for rental
  const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
    rentalIncomeWithPropertyWorthPercentage,
    isManagementFeeApplied ? 100 - management_fee : 0
  )
  // for appreciation
  const appreciationAfterManagementFee = calculateAfterManagementFeePercentages(
    calculatedARQAppreciationWithEquity,
    isManagementFeeApplied ? 100 - management_fee : 0
  )
  const final_fixAndFlip = isLegacy
    ? flipGainWithPropertyWorthPercentage
    : flipGainAfterManagementFee
  const final_rentalIncome = isLegacy
    ? rentalIncomeWithPropertyWorthPercentage
    : rentalIncomeAfterManagementFee
  const final_appreciation = isLegacy
    ? appreciationWithPropertyWorthPercentage
    : appreciationAfterManagementFee
  let rentalIncomeThisPeriod = final_rentalIncome * rentalFrequency
  const incrementalIncome = rentalIncomeThisPeriod * period
  const final_fixAndFlip_yearly = isLegacy
    ? final_fixAndFlip
    : period === numberOfYears || (numberOfYears === 1 && period === 12)
    ? final_fixAndFlip
    : 0
  const futureValue =
    initialDeposit + final_fixAndFlip_yearly + final_rentalIncome + final_appreciation * period
  const gains = Math.abs(futureValue - initialDeposit)
  const customROR = initialDeposit > 0 ? gains / initialDeposit / period : gains / period
  const ror = initialDeposit > 0 ? gains / initialDeposit : gains
  const monthIndex = (currentMonth + Math.floor(period * 12)) % 12
  const year = currentYear + Math.floor(period)
  const currentDateForMonth = new Date(year, monthIndex, 1)
  const monthName = currentDateForMonth.toLocaleString('default', {month: 'long'})
  const totalCashOnCash = incrementalIncome / initialDeposit
  const cashOnCash = (rentalIncomeThisPeriod + final_fixAndFlip_yearly) / initialDeposit
  if (numberOfYears === 1) {
    rentalIncomeThisPeriod = rentalIncomeThisPeriod / 12
  }
  const tableData: InvestmentTableModel = {
    yearOrMonth: numberOfYears === 1 ? monthName : year.toString(),
    initialDeposit: +initialDeposit.toFixed(2),
    contributionsThisPeriod: null,
    cumulativeContributions: null,
    gainThisPeriod: null,
    cumulativeGains: null,
    futureValue: +futureValue.toFixed(2),
    gain: selectedRate.value !== 0 ? +(futureValue - initialDeposit).toFixed(2) : 0,
    isMonthly: numberOfYears === 1,
    selectedRate: selectedRate.dropdownLabel || '',
    customROR: isForTable ? `${(customROR * 100).toFixed(2)}%` : `${(customROR * 100).toFixed(6)}%`,
    ROR: isForTable ? `${(ror * 100).toFixed(2)}%` : `${(ror * 100).toFixed(6)}%`,
    flipGain: final_fixAndFlip_yearly,
    rentalIncome: rentalIncomeThisPeriod,
    appreciation: final_appreciation,
    cashOnCash: +(cashOnCash * 100).toFixed(2),
    totalCashOnCash: +(totalCashOnCash * 100).toFixed(2),
    isLoss: false,
    originalARQValues: {
      proportionValue: propertyWorth !== 0 ? (initialDeposit / propertyWorth) * 100 : 0,
      fixAndFlip: flipGain,
      rental: rentalIncome * rentalFrequency * period,
      appreciation: appreciation,
      management_fee: 0,
    },
  }

  return tableData
}

const calculateBeforeExitProfits = (
  initialDeposit: number,
  numberOfYears: number,
  selectedRate: CompoundingGrowth,
  flipGain: number,
  propertyWorth: number,
  rentalFrequency: number,
  rentalIncome: number,
  management_fee: number,
  period: number,
  isForTable: boolean
): InvestmentTableModel => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const rentalIncomeWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
    rentalIncome,
    propertyWorth,
    initialDeposit
  )

  const rentalIncomeAfterManagementFee = calculateAfterManagementFeePercentages(
    rentalIncomeWithPropertyWorthPercentage,
    100 - management_fee
  )

  let rentalIncomeThisPeriod = rentalIncomeAfterManagementFee * rentalFrequency
  const incrementalIncome = rentalIncomeThisPeriod * period
  const futureValue = initialDeposit + incrementalIncome

  const gains = incrementalIncome * period

  var customROR = initialDeposit > 0 ? gains / initialDeposit / period : gains / period
  const ror = customROR
  customROR = customROR / period

  const monthIndex = (currentMonth + Math.floor(period * 12)) % 12
  const year = currentYear + Math.floor(period)
  const currentDateForMonth = new Date(year, monthIndex, 1)
  const monthName = currentDateForMonth.toLocaleString('default', {month: 'long'})
  const totalCashOnCash = incrementalIncome / initialDeposit
  const cashOnCash = rentalIncomeThisPeriod / initialDeposit

  if (numberOfYears === 1) {
    rentalIncomeThisPeriod = rentalIncomeThisPeriod / 12
  }
  const tableData: InvestmentTableModel = {
    yearOrMonth: numberOfYears === 1 ? monthName : year.toString(),
    initialDeposit: +initialDeposit.toFixed(2),
    contributionsThisPeriod: null,
    cumulativeContributions: null,
    gainThisPeriod: null,
    cumulativeGains: null,
    futureValue: +futureValue.toFixed(2),
    gain: selectedRate.value !== 0 ? +(futureValue - initialDeposit).toFixed(2) : 0,
    isMonthly: numberOfYears === 1,
    selectedRate: selectedRate.dropdownLabel || '',
    customROR: isForTable ? `${(customROR * 100).toFixed(2)}%` : `${(customROR * 100).toFixed(6)}%`,
    ROR: isForTable ? `${(ror * 100).toFixed(2)}%` : `${(ror * 100).toFixed(6)}%`,
    flipGain: 0,
    rentalIncome: rentalIncomeThisPeriod,
    appreciation: 0,
    isLoss: false,
    cashOnCash: +(cashOnCash * 100).toFixed(2),
    totalCashOnCash: +(totalCashOnCash * 100).toFixed(2),
    originalARQValues: {
      proportionValue: propertyWorth !== 0 ? (initialDeposit / propertyWorth) * 100 : 0,
      fixAndFlip: flipGain,
      rental: rentalIncome * rentalFrequency * period,
      appreciation: 0,
      management_fee: management_fee,
    },
  }

  return tableData
}

export const generateTableData = (
  initialDeposit: number,
  contributions: number,
  contributionFrequency: number,
  numberOfYears: number,
  selectedRate: CompoundingGrowth,
  flipGain: number,
  propertyWorth: number,
  appreciation: number,
  rentalFrequency: number,
  rentalIncome: number,
  management_fee: number,
  rollover: boolean,
  newStartingAmount: number,
  propertyWorthEndValue: number,
  customerActual: boolean,
  is_management_fee_applied: boolean
): InvestmentTableModel[] => {
  const data: InvestmentTableModel[] = []
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  if (selectedRate.type === TYPES_OF_ROR.TYPE_SIMPLE) {
    if (numberOfYears === 1) {
      // Loop through each month within the year
      for (let month = 1; month <= 12; month++) {
        data.push(
          calculateSimpleInvestmentDataForPeriod(
            initialDeposit,
            selectedRate,
            contributions,
            contributionFrequency,
            numberOfYears,
            month / 12,
            1,
            data[data.length - 1]?.cumulativeGains ?? 0
          )
        )
      }
    } else {
      for (let i = 1; i <= numberOfYears; i++) {
        data.push(
          calculateSimpleInvestmentDataForPeriod(
            initialDeposit,
            selectedRate,
            contributions,
            contributionFrequency,
            numberOfYears,
            i,
            contributionFrequency,
            data[data.length - 1]?.cumulativeGains ?? 0
          )
        )
      }
    }
  } else {
    let futureValue = 0
    let gains = 0
    let customROR = null
    const flipGainWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      flipGain,
      propertyWorth,
      initialDeposit
    )
    const appreciationWithPropertyWorthPercentage = calculatePropertyWorthPercentages(
      appreciation,
      propertyWorth,
      initialDeposit
    )

    if (selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
      futureValue = initialDeposit + flipGainWithPropertyWorthPercentage
      gains = flipGainWithPropertyWorthPercentage + appreciationWithPropertyWorthPercentage
      customROR = initialDeposit > 0 ? Math.abs(initialDeposit - futureValue) / initialDeposit : 0

      const tableData: InvestmentTableModel = {
        yearOrMonth: currentYear.toString(),
        initialDeposit: +initialDeposit.toFixed(2),
        contributionsThisPeriod: null,
        cumulativeContributions: null,
        gainThisPeriod: null,
        cumulativeGains: null,
        futureValue: +futureValue.toFixed(2),
        gain: selectedRate.value !== 0 ? +(futureValue - initialDeposit).toFixed(2) : 0,
        isMonthly: null,
        selectedRate: selectedRate.dropdownLabel || '',
        customROR: `${(customROR * 100).toFixed(2)}%`,
        ROR: `${(customROR * 100).toFixed(2)}%`,
        flipGain: flipGainWithPropertyWorthPercentage,
        rentalIncome: rentalIncome,
        appreciation: 0,
        cashOnCash: null,
        totalCashOnCash: null,
        isLoss: false,
        originalARQValues: {
          proportionValue: propertyWorth !== 0 ? (initialDeposit / propertyWorth) * 100 : 0,
          fixAndFlip: flipGain,
          rental: 0,
          appreciation: 0,
          management_fee: management_fee,
        },
      }

      data.push(tableData)
    } else if (
      selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL ||
      selectedRate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT
    ) {
      if (numberOfYears === 1) {
        // Loop through each month within the year for rental calculations
        for (let month = 1; month <= 12; month++) {
          data.push(
            calculateRentalProfits(
              initialDeposit,
              numberOfYears,
              selectedRate,
              flipGain,
              propertyWorth,
              appreciation,
              rentalFrequency,
              rentalIncome,
              management_fee,
              month / 12,
              selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL,
              true,
              rollover,
              newStartingAmount,
              propertyWorthEndValue,
              customerActual,
              is_management_fee_applied
            )
          )
        }
      } else {
        for (let i = 1; i <= numberOfYears; i++) {
          data.push(
            calculateRentalProfits(
              initialDeposit,
              numberOfYears,
              selectedRate,
              flipGain,
              propertyWorth,
              appreciation,
              rentalFrequency,
              rentalIncome,
              management_fee,
              i,
              selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL,
              true,
              rollover,
              newStartingAmount,
              propertyWorthEndValue,
              customerActual,
              is_management_fee_applied
            )
          )
        }
      }
    } else if (selectedRate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT) {
      if (numberOfYears === 1) {
        // Loop through each month within the year for rental calculations
        for (let month = 1; month <= 12; month++) {
          data.push(
            calculateBeforeExitProfits(
              initialDeposit,
              numberOfYears,
              selectedRate,
              flipGain,
              propertyWorth,
              rentalFrequency,
              rentalIncome,
              management_fee,
              month / 12,
              true
            )
          )
        }
      } else {
        for (let i = 1; i <= numberOfYears; i++) {
          data.push(
            calculateBeforeExitProfits(
              initialDeposit,
              numberOfYears,
              selectedRate,
              flipGain,
              propertyWorth,
              rentalFrequency,
              rentalIncome,
              management_fee,
              i,
              true
            )
          )
        }
      }
    }
  }
  return data
}

export const calculateBottomLineData = (
  initialDeposit: number,
  numberOfYears: number,
  selectedRate: CompoundingGrowth,
  flipGain: number,
  propertyWorth: number,
  appreciation: number,
  rentalFrequency: number,
  rentalIncome: number,
  management_fee: number,
  rollover: boolean,
  newStartingAmount: number,
  propertyWorthEndValue: number,
  customerActual: boolean,
  is_management_fee_applied: boolean
): BottomLineModel => {
  let _bottomLineData: BottomLineModel
  let _beforeExitTableData = calculateBeforeExitProfits(
    initialDeposit,
    numberOfYears,
    selectedRate,
    flipGain,
    propertyWorth,
    rentalFrequency,
    rentalIncome,
    management_fee,
    numberOfYears,
    false
  )
  let _afterExitTableData = calculateRentalProfits(
    initialDeposit,
    numberOfYears,
    selectedRate,
    flipGain,
    propertyWorth,
    appreciation,
    rentalFrequency,
    rentalIncome,
    management_fee,
    numberOfYears,
    false,
    false,
    rollover,
    newStartingAmount,
    propertyWorthEndValue,
    customerActual,
    is_management_fee_applied
  )

  let _beforeExitData = {
    annualizedROR: _beforeExitTableData?.customROR
      ? parseFloat(_beforeExitTableData.customROR.replace('%', '')).toFixed(2) + '%'
      : '',
    totalValue: _beforeExitTableData.futureValue,
    totalROI: _beforeExitTableData?.ROR
      ? parseFloat(_beforeExitTableData.ROR.replace('%', '')).toFixed(2) + '%'
      : '',
    totalROIValue: +(+_beforeExitTableData.ROR.replace('%', '') * 1000).toFixed(2),
    totalAnnualizedRORValue:
      _beforeExitTableData.customROR !== null
        ? +(+_beforeExitTableData.customROR.replace('%', '') * 1000).toFixed(2)
        : 0,
  }
  let _afterExitData = {
    annualizedROR: _afterExitTableData?.customROR
      ? parseFloat(_afterExitTableData.customROR.replace('%', '')).toFixed(2) + '%'
      : '',
    totalValue: _afterExitTableData.futureValue,
    totalROI: _afterExitTableData?.ROR
      ? parseFloat(_afterExitTableData.ROR.replace('%', '')).toFixed(2) + '%'
      : '',
    totalROIValue: +(+_afterExitTableData.ROR.replace('%', '') * 1000).toFixed(2),
    totalAnnualizedRORValue:
      _afterExitTableData.customROR !== null
        ? +(+_afterExitTableData.customROR.replace('%', '') * 1000).toFixed(2)
        : 0,
  }
  let propertyWorthPercentage = initialDeposit !== 0 ? initialDeposit / propertyWorth : 0
  _bottomLineData = {
    propertyWorthPercentage: parseFloat((propertyWorthPercentage * 100).toFixed(2)),
    numberOfYears,
    beforeExit: _beforeExitData,
    afterExit: _afterExitData,
    chartData: [
      {
        name: 'initialDeposit',
        initialDeposit,
        beforeExit: 0,
        afterExit: 0,
      },
      {
        name: 'beforeExit',
        initialDeposit,
        beforeExit: _beforeExitData.totalValue,
        afterExit: 0,
      },
      {
        name: 'afterExit',
        initialDeposit,
        beforeExit: 0,
        afterExit: _afterExitData.totalValue,
      },
    ],
  }

  return _bottomLineData
}
