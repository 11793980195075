import React, { useRef, useState, useEffect } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ['places'];

const AutoCompleteField = ({ placeholder, onPlaceSelected, defaultAddress }) => {
  const autocompleteRef = useRef(null);
  const [address, setAddress] = useState(defaultAddress || ''); // Initialize with defaultAddress
 const api_key = 'AIzaSyBmZ8qhoojM1kZGbPK9Cdhx4Pp8Kr3okuY'
  useEffect(() => {
    // Update the address if the defaultAddress prop changes
    if (defaultAddress) {
      setAddress(defaultAddress);
    }
  }, [defaultAddress]);

  const handlePlaceChanged = async () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const formattedAddress = place.formatted_address;

      if (formattedAddress) {
        setAddress(formattedAddress); // Update the input field
        onPlaceSelected({
          lat: place.geometry.location.lat() || 40,
          lng: place.geometry.location.lng() || 73,
          address: formattedAddress,
        });
      }
    }
  };

  const handleInputChange = (e) => {
    setAddress(e.target.value); // Update address on user input
  };

  return (
    <LoadScript googleMapsApiKey={api_key} libraries={libraries}>
      <Autocomplete
        onLoad={(ref) => (autocompleteRef.current = ref)}
        onPlaceChanged={handlePlaceChanged}
      >
        <input
          type="text"
          placeholder={placeholder}
          value={address} // Controlled value
          onChange={handleInputChange} // Handle manual input changes
          className="form-control"
          style={{
            padding: '10px',
            borderRadius: '8px',
            border: '1px solid #ddd',
            marginBottom: '12px',
            width: '100%',
            boxSizing: 'border-box',
            color: 'black',
          }}
        />
      </Autocomplete>
    </LoadScript>
  );
};

export default AutoCompleteField;
