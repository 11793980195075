import {useState} from 'react'
import ProjectionsChart from '../../modules/homeComponents/projections/components/ProjectionsChart'
import TimeFrameSelector from '../../modules/homeComponents/myPerformance/TimeFrameSelector'

const MobileSuperChart: React.FC = () => {
  const [currentChartId, setCurrentChartId] = useState<number>(0)
  const [currentChartName, setCurrentChartName] = useState<string>('QTD')
  let data = [
    {
      ARQ_Flip_Gain: 0,
      ARQ_appreciation: 0,
      ARQ_property_worth: 10000,
      ARQ_rental_contribution: 12,
      ARQ_rental_income: 0,
      contributions: 0,
      customLabel: '-2.4%',
      customROR: null,
      futureValue:
        currentChartId === 0
          ? 900.4
          : currentChartId === 1
          ? 600
          : currentChartId === 2
          ? 800
          : currentChartId === 3
          ? 300
          : 1000,
      futureValueWithoutContributions: -231.59608522891335,
      id: 0,
      initialDeposit: 1000,
      isLoss: true,
      label: 'Cash Under Mattress',
      labelColor: '#B28F22',
      numberOfYears: 10,
      originalARQValues: {
        proportionValue: 10,
        fixAndFlip: 0,
        rental: 12000,
        appreciation: 0,
        management_fee: 80,
      },
      type: 'Simple RoR',
      value: -2.4,
    },
    {
      ARQ_Flip_Gain: 0,
      ARQ_appreciation: 0,
      ARQ_property_worth: 10000,
      ARQ_rental_contribution: 12,
      ARQ_rental_income: 0,
      contributions: 0,
      customLabel: '7%',
      customROR: null,
      futureValue:
        currentChartId === 0
          ? 900.4
          : currentChartId === 1
          ? 600
          : currentChartId === 2
          ? 400
          : currentChartId === 3
          ? 300
          : 1000,
      futureValueWithoutContributions: -231.59608522891335,
      id: 0,
      initialDeposit: 1000,
      isLoss: true,
      label: 'Stocks',
      labelColor: '#B28F22',
      numberOfYears: 10,
      originalARQValues: {
        proportionValue: 10,
        fixAndFlip: 0,
        rental: 12000,
        appreciation: 0,
        management_fee: 80,
      },
      type: 'Simple RoR',
      value: -2.6,
    },
  ]
  const handleTimeFrameSelection = (index: number, value: string) => {
    setCurrentChartId(index)
    setCurrentChartName(value)
  }

  return (
    <>
      <div className='mt-20'>
        <ProjectionsChart data={data} chartColor='transparent' isForArqMobile={true} />
        <div className='px-20 py-3'>
          <TimeFrameSelector
            onTimeFrameSelected={handleTimeFrameSelection}
            currentChartName={currentChartName}
          />
        </div>
      </div>
    </>
  )
}

export default MobileSuperChart
