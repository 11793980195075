import React, {useState, ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef} from 'react'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'

interface EditableCellProps {
  value: string
  rowIndex: number
  keyId: string
  onChange: (rowIndex: number, keyId: string, newValue: string) => void
  dragging: boolean // Added to indicate if drag-to-select is active
  onToggleSelect: (rowIndex: number, isShift: boolean) => void
  isEditable: boolean
  isNegativeAllowed?: boolean // Allow negative numbers
  isDecimalAllowed?: boolean // Optional prop to allow decimals (default: false)
  selectedRows?: boolean
}

const EditableCell: React.FC<EditableCellProps> = ({
  value,
  rowIndex,
  keyId,
  onChange,
  dragging,
  onToggleSelect,
  isEditable,
  isNegativeAllowed = false,
  isDecimalAllowed = false, // Default value is false
  selectedRows = false,
}) => {
  const [editing, setEditing] = useState(false)
  const [cellValue, setCellValue] = useState<string>(value)
  const inputRef = useRef<HTMLInputElement>(null)

  const formatCurrency = (val: string) => {
    if (val.trim() === '') return ''

    const numericValue = parseFloat(val)
    return isNaN(numericValue) ? '' : `$${numericValue.toFixed(2)}`
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setEditing(false)
    const formattedValue = formatCurrency(cellValue)
    if (formattedValue !== '') {
      onChange(rowIndex, keyId, formattedValue)
    } else {
      onChange(rowIndex, keyId, formatCurrency(value)) // Reset to original value if input is empty
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    onToggleSelect(rowIndex, false)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setEditing(false)
      const formattedValue = formatCurrency(cellValue.trim() !== '' ? cellValue : value)
      onChange(rowIndex, keyId, formattedValue)
    }

    // Check if the Backspace key is pressed and clear the input
    if (e.key === 'Backspace') {
      setCellValue('') // Clear the input value
    }
  }

  const activateEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    setEditing(true)

    let initialValue = e.currentTarget.innerText

    // Remove any characters that are not digits, minus sign, or decimal point
    initialValue = initialValue.replace(/[^\d.-]/g, '')

    // Remove everything after the decimal point, if it exists
    // initialValue = initialValue.replace(/\.\d*$/, '')

    if (isNegativeAllowed) {
      // Ensure only the first minus sign is kept if negative numbers are allowed
      if (initialValue.startsWith('-')) {
        // Remove all other minus signs except the first
        initialValue = '-' + initialValue.slice(1).replace(/-/g, '')
      }
    } else {
      // Remove all minus signs if negative numbers are not allowed
      initialValue = initialValue.replace(/-/g, '')
    }

    setCellValue(initialValue)
  }

  const handleMouseEnter = () => {
    if (dragging) {
      onToggleSelect(rowIndex, false)
    }
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isEditable) {
      onToggleSelect(rowIndex, e.shiftKey)
    }
    if (isEditable && !selectedRows) {
      onToggleSelect(rowIndex, e.shiftKey)
    }
  }
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(0, inputRef.current.value.length) // Select the input value
    }
  }, [editing])
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onClick={activateEdit}
      style={{width: '100%', height: '100%'}}
      className='d-flex justify-content-center align-items-center text-align-center'
    >
      {isEditable ? (
        editing ? (
          <CustomInputField
            id='table-input'
            decimalsLimit={2}
            maxLength={11}
            noPrefix={isDecimalAllowed?true:false}
            value={cellValue}
            onValueChange={(value: string | undefined) => {
              let parsedValue: string = value || ''
              if (isDecimalAllowed) {
                // If decimal is allowed, parse the float and ensure it has up to 2 decimal places
                parsedValue = `${value}`
              } else {
                // If decimal is NOT allowed, remove any decimal points and keep only whole numbers
                parsedValue = parsedValue.replace(/\D/g, '') // Remove all non-digit characters
              }
              setCellValue(parsedValue) // Set the value in state
            }}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            onClick={handleClick}
            style={{width: '50%', color: 'black'}}
            ref={inputRef}
          />
        ) : (
          <span>{value !== '-1' ? value : '-'}</span>
        )
      ) : (
        <span>{value !== '-1' ? value : '-'}</span>
      )}
    </div>
  )
}

export default EditableCell
