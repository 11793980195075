import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from '../../../helper/charts.helper'

interface CustomTooltipProps extends TooltipProps<number, string> {
  totalIndices: number[] // Add the totalIndices prop
  dragged: boolean // Add the dragged prop
  combinedDataOfDraggedSelectedBars: any
}

const IBLOCTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  totalIndices,
  dragged,
  combinedDataOfDraggedSelectedBars,
}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
  const index = (data?.Year - 3) as number // Access the index from the payload

  // Determine if we should show the tooltip based on dragged status
  const shouldShowTooltip = dragged ? totalIndices.includes(index) : true

  if (shouldShowTooltip) {
    return dragged ? (
      <div className='custom-tooltip sbloc-tooltip-container'>
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>
            Total Cash In These Years:
          </p>
          <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>
            +{toUSD(combinedDataOfDraggedSelectedBars?.cashInSum)}
          </p>
        </div>
        <div className='d-flex justify-content-between align-items-center mb-5'>
          <p className='label-bold paragraph-x-small mb-0 label-borrow'>
            Total Cash Out These Years:
          </p>
          <p className='label-bold paragraph-x-small mb-0 label-borrow'>
            -{toUSD(combinedDataOfDraggedSelectedBars?.cashOutSum)}
          </p>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0'>ROI on Cash In These Years:</p>
          <p className='label-bold paragraph-x-small mb-0'>
            {isFinite(parseFloat(combinedDataOfDraggedSelectedBars?.CalculatedRoiOnCash))
              ? parseFloat(combinedDataOfDraggedSelectedBars?.CalculatedRoiOnCash)?.toFixed(2)
              : 0}
            %
          </p>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0'>ROI if Cash was in S&P These Years</p>
          <p className='label-bold paragraph-x-small mb-0'>
            {isFinite(parseFloat(combinedDataOfDraggedSelectedBars?.RoiOnSPCash))
              ? parseFloat(combinedDataOfDraggedSelectedBars?.RoiOnSPCash)?.toFixed(2)
              : 0}
            %
          </p>
        </div>
      </div>
    ) : (
      <div className='custom-tooltip sbloc-tooltip-container'>
        <span className='label-bold'>Total</span>
        {/* Total Cash Value */}
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <p className='label-bold paragraph-x-small mb-0'>Total Cash Value: </p>
          <p className='label-bold paragraph-x-small mb-0'>{toUSD(data?.TCVDelta)}</p>
        </div>
        {/* CashBalance */}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0'>Cash Balance:</p>
          <p className='label-bold paragraph-x-small mb-0'>+{toUSD(data.CBDelta)}</p>
        </div>
        {/* CV growth */}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small label-initial-deposit mb-0 mt-5'>
            CV growth:{' '}
          </p>
          <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>
            {`${data.CvGrowthYoY?.toFixed(2)}%`}
          </p>
        </div>
        {/* Cash BAlance Growth */}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>
            Cash balance growth:{' '}
          </p>
          <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>
            {`${data.CashBalanceGrowth?.toFixed(2)}%`}
          </p>
        </div>

        {/* CashIn */}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0 label-re-invest mt-5'>Cash In</p>
          <p className='label-bold paragraph-x-small mb-0 label-re-invest'>+{toUSD(data.CashIn)}</p>
        </div>
        {/* CashOut */}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0 label-borrow'>Cash Out: </p>
          <p className='label-bold paragraph-x-small mb-0 label-borrow'>-{toUSD(data.CashOut)}</p>
        </div>
        {/* IBLOCBalance */}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0'>IBLOC Balance: </p>
          <p className='label-bold paragraph-x-small mb-0'>+{toUSD(data.IBLOCBalance)} </p>
        </div>
        {/* Cash on Cash ROI */}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0 label-gain mt-5'>Cash on Cash ROI: </p>
          <p className='label-bold paragraph-x-small mb-0 label-gain'>
            {isFinite(data.CashOnCashROI) && !isNaN(data.CashOnCashROI)
              ? `${data.CashOnCashROI?.toFixed(2)}%`
              : '0%'}
          </p>
        </div>

        {/*TCOOROI*/}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0 label-re-invest'>Opp. Cost ROI: </p>
          <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
            {isFinite(data.TCOOROI) && !isNaN(data.TCOOROI) ? `${data.TCOOROI?.toFixed(2)}%` : '0%'}
          </p>
        </div>

        {/*  Total Cash Opportunity Cost */}
        <div className='d-flex justify-content-between align-items-center'>
          <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
            Total Cash Opportunity Cost:
          </p>
          <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
            +{toUSD(data.TotalCashOpportunity)}
          </p>
        </div>
        {/* APR*/}
        <div className='d-flex justify-content-between align-items-center mb-8'>
          <p className='label-bold paragraph-x-small mb-0'>APR this year:</p>
          <p className='label-bold paragraph-x-small mb-0'> {`${data.APR?.toFixed(2)}%`}</p>
        </div>
      </div>
    )
  }

  return null // Return null if tooltip should not be shown
}

export default IBLOCTooltip
