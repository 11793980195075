import React, {createContext, useState, useContext, Dispatch, SetStateAction, useMemo} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {IBLOCDefaultData} from '../modules/homeComponents/IBLOC/Components/IBLOCConfigs'
import {cloneDeep} from 'lodash'

type SBLOCData = {
  starting_amount: number
  asset_rate_of_return: number
  years_to_grow: number
  borrowed_amount: number
  annualized_interest_rate: number
  re_invested_amount: number
  re_invest_rate_of_return: number
  cash_flow_that_year: number
  cash_flow_frequency: number
}

// SBLOC context props
type SBLOCContextProps = {
  SBLOCObj: SBLOCData
  setSBLOCObj: Dispatch<SetStateAction<SBLOCData>>
  IBLOCArray: any[]
  setIBLOCArray: Dispatch<SetStateAction<any[]>>
  parentUserId: number
  setParentUserId: Dispatch<SetStateAction<number>>
  personaData: any
  setPersonaData: Dispatch<SetStateAction<any>>
  isFullView: boolean
  setIsFullView: Dispatch<SetStateAction<boolean>>
  compareScenarioTitle: any
  setCompareScenarioTitle: Dispatch<SetStateAction<any>>
  defaultOpportunityCostROI: any
  setDefaultOpportunityCostROI: Dispatch<SetStateAction<any>>
  compareScenarioChartData: any
  setCompareScenarioChartData: Dispatch<SetStateAction<any>>
  compareScenarioTableData: any
  setCompareScenarioTableData: Dispatch<SetStateAction<any>>
  selectedChartIndex: number
  setSelectedChartIndex: Dispatch<SetStateAction<number>>
}

type CompareScenarioData = {
  id: number
  value: any[] // Adjust `any` based on the exact structure of your data
  label: string
}

const tableDataToStore = {
  id: 0,
  value: cloneDeep([...IBLOCDefaultData]),
  label: 'Default Scenario',
}

const chartDataToStore = {
  id: 0,
  value: cloneDeep([...IBLOCDefaultData]),
  label: 'Default Scenario',
}

// default values
const initSBLOCContextPropsState = {
  SBLOCObj: {
    annualized_interest_rate: 8,
    asset_rate_of_return: 9,
    borrowed_amount: 2000000,
    re_invest_rate_of_return: 10,
    re_invested_amount: 1000000,
    starting_amount: 4000000,
    years_to_grow: 15,
    cash_flow_that_year: 0,
    cash_flow_frequency: 0,
  },
  IBLOCArray: cloneDeep([...IBLOCDefaultData]),
  setIBLOCArray: () => {},
  setSBLOCObj: () => {},
  parentUserId: 0,
  setParentUserId: () => {},
  personaData: {},
  setPersonaData: () => {},
  isFullView: false,
  setIsFullView: () => {},
  compareScenarioTitle: () => {},
  setCompareScenarioTitle: () => {},
  compareScenarioChartData: [cloneDeep(chartDataToStore)],
  setCompareScenarioChartData: () => {},
  compareScenarioTableData: [cloneDeep(tableDataToStore)],
  setCompareScenarioTableData: () => {},
  selectedChartIndex: 0,
  setSelectedChartIndex: () => {},
  defaultOpportunityCostROI: 7,
  setDefaultOpportunityCostROI: () => {},
}

const SBLOCContext = createContext<SBLOCContextProps>(initSBLOCContextPropsState)

const SBLOCProvider: React.FC<WithChildren> = React.memo(({children}) => {
  const [SBLOCObj, setSBLOCObj] = useState(initSBLOCContextPropsState.SBLOCObj)
  const [IBLOCArray, setIBLOCArray] = useState(initSBLOCContextPropsState.IBLOCArray)
  const [parentUserId, setParentUserId] = useState(0)
  const [isFullView, setIsFullView] = useState(true)
  const [personaData, setPersonaData] = useState<any>([])
  const [compareScenarioTitle, setCompareScenarioTitle] = useState<string>('')
  const [defaultOpportunityCostROI, setDefaultOpportunityCostROI] = useState<number>(7)
  const [compareScenarioChartData, setCompareScenarioChartData] = useState<CompareScenarioData[]>(
    initSBLOCContextPropsState.compareScenarioChartData
  )
  const [compareScenarioTableData, setCompareScenarioTableData] = useState<CompareScenarioData[]>(
    initSBLOCContextPropsState.compareScenarioTableData
  )
  const [selectedChartIndex, setSelectedChartIndex] = useState<number>(0)
  // This useMemo ensures that the context value only changes when any of the context properties change
  const contextValue = useMemo(
    () => ({
      SBLOCObj,
      setSBLOCObj,
      IBLOCArray,
      setIBLOCArray,
      parentUserId,
      setParentUserId,
      personaData,
      setPersonaData,
      isFullView,
      setIsFullView,
      compareScenarioTitle,
      setCompareScenarioTitle,
      compareScenarioChartData,
      setCompareScenarioChartData,
      compareScenarioTableData,
      setCompareScenarioTableData,
      selectedChartIndex,
      setSelectedChartIndex,
      defaultOpportunityCostROI,
      setDefaultOpportunityCostROI,
    }),
    [
      SBLOCObj,
      parentUserId,
      personaData,
      IBLOCArray,
      isFullView,
      compareScenarioTitle,
      compareScenarioChartData,
      compareScenarioTableData,
      selectedChartIndex,
      defaultOpportunityCostROI,
    ]
  )
  return <SBLOCContext.Provider value={contextValue}>{children}</SBLOCContext.Provider>
})

const useSBLOCContext = () => {
  const context = useContext(SBLOCContext)
  if (!context) {
    throw new Error('useSBLOCContext must be used within a SBLOCProvider')
  }
  return context
}

export {SBLOCProvider, useSBLOCContext}
