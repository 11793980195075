import {Table} from 'antd'
import {toUSDWithoutRoundOff} from '../../../helper/charts.helper'

const GroupedTable: React.FC<{
  tableData: any[]
  onRowClicked: (data: any, event: any) => void
  rowStyles: any
  selectedRowIndexes: any[]
}> = ({tableData, onRowClicked, rowStyles, selectedRowIndexes}) => {
  const columns = [
    {
      title: 'Year',
      dataIndex: 'Year',
      key: 'year',
      // className: 'white-background', // Apply custom class for styling
      render: (value: number) => value,
    },
    {
      title: 'Age',
      dataIndex: 'Age',
      key: 'Age',
      // className: 'white-background', // Apply custom class for styling
      render: (value: number) => value,
    },
    {
      title: 'Premium Breakdown',
      className: 'grey-background text-green',
      children: [
        {
          title: 'Base Contract Premium',
          dataIndex: 'BaseContractPremium',
          key: 'base',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'FPR Contract Premium',
          dataIndex: 'FPRContractPremium',
          key: 'fpr',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'APPUA Premium',
          dataIndex: 'APPUAPremium',
          key: 'appua',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'Total Premium',
          dataIndex: 'TotalPremium',
          key: 'totalPremium',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
      ],
    },
    {
      title: 'Guaranteed',
      className: 'white-background text-green',
      children: [
        {
          title: 'Total Cash Value',
          dataIndex: 'TotalCashValue',
          key: 'TotalCashValue',
          // className: 'white-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'Increase In Total Cash Value',
          dataIndex: 'IncreaseInTotalCashValue',
          key: 'IncreaseInTotalCashValue',
          // className: 'white-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'Total Death Benefit',
          dataIndex: 'TotalDeathBenefit',
          key: 'TotalDeathBenefit',
          // className: 'white-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
      ],
    },
    {
      title: 'Non-Guaranteed',
      className: 'grey-background text-green',
      children: [
        {
          title: 'Dividend',
          dataIndex: 'Dividend',
          key: 'Dividend',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'Total Cash Value',
          dataIndex: 'DividendTotalCashValue',
          key: 'DividendTotalCashValue',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'Increase In Total Cash Value',
          dataIndex: 'DividendIncreaseInTotalCashValue',
          key: 'DividendIncreaseInTotalCashValue',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'Death Benefit without Dividends',
          dataIndex: 'DeathBenefitWithoutDividends',
          key: 'deathBenefitWithoutDividends',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
        {
          title: 'Total Death Benefit',
          dataIndex: 'DividendTotalDeathBenefit',
          key: 'DividendTotalDeathBenefit',
          // className: 'grey-background',
          render: (value: number) => toUSDWithoutRoundOff(value),
        },
      ],
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      scroll={{x: 1300}}
      onRow={(record) => ({
        onClick: () => {
          onRowClicked(record, false)
        },
      })}
      rowClassName={(record, index) =>
        selectedRowIndexes.includes(index) ? 'editable-row' : 'white-background'
      }
    />
  )
}

export default GroupedTable
