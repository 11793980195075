import ReactGA from 'react-ga4'
ReactGA.initialize('G-KMJDCBKPY1')
export const sendAnalytics = async (featureTitle) => {
  ReactGA.send({hitType: 'pageview', page: window.location.pathname, title: featureTitle})
}
export const sendToSheets = async (Address1Start, Address1End, Address2Start, Address2End,date) => {
  const encodesAddress1Start = encodeURIComponent(Address1Start)
  const encodesAddress1End = encodeURIComponent(Address1End)
  const encodesAddress2Start = encodeURIComponent(Address2Start)
  const encodesAddress2End = encodeURIComponent(Address2End)
  const current_date = encodeURIComponent(date)
  //    https://script.google.com/macros/s/AKfycbxBa-88o1nBhg3VxCyWwvPdu6cnAOxME0pZKCgvqcQebWklX2OBHqrjldQayNcRBBzqbQ/exec
  const scriptUrl = `${process.env.REACT_APP_GOOGLE_SHEETS_DEPLOYMENT_URL}?current_date=${current_date}&Address1Start=${encodesAddress1Start}&Address1End=${encodesAddress1End}&Address2Start=${encodesAddress2Start}&Address2End=${encodesAddress2End}&isMaps=true`
  try {
    const response = await fetch(scriptUrl)
    if (response.status === 200) {
      return
    } else {
      console.error('Error sending message:', response.statusText)
    }
  } catch (error) {
    console.error('Error sending message:', error)
  }
}
