import React from 'react'
import CurrencyInput from 'react-currency-input-field'

interface CustomInputFieldProps {
  noPrefix?: boolean // Optional prop, default is false
  [key: string]: any // For other props like step, min, etc.
}

function CustomInputField({noPrefix = false, ...props}: CustomInputFieldProps) {
  return (
    <CurrencyInput
      prefix={noPrefix ? '' : '$'} // Remove prefix if noPrefix is true
      step={1}
      min={0}
      {...props} // Spread the rest of the props
    />
  )
}

export default CustomInputField
