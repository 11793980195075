import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ILayout, useLayout} from '../../../core'
import {
  FEATURE_COMING_SOON,
  FINANCIAL_SECTION,
  FOOTER_DATA,
  sectionIds,
} from '../../../../../app/constants/AppConstants'
import VerticallyCenteredModal from '../../modal/VerticallyCenteredModal'
import {useReceiverFormContext} from '../../../../../app/user/context/ReceiverContext'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()
  const [showThankYouModal, setShowThankYouModal] = useState(false)

  const navigate = useNavigate()

  const handleOnClickMail = () => {
    navigate('/contact')
  }

  const currentHostname = window.location.hostname

  return (
    <>
      <footer className='px-7 px-md-10 px-lg-20'>
        <div className='row px-7 px-md-10 px-lg-20 position-relative z-index-1' id={sectionIds[13]}>
          {/* <div className='col-lg-6 custom-opacity'>
            <h2>{FOOTER_DATA.JOIN_TEAM}</h2>
            <p className='paragraph-small mb-0 fw-300'>{FOOTER_DATA.MISSION}</p>
          </div> */}
          {/* <div className='col-lg-6 d-flex flex-column flex-sm-row justify-content-between align-items-center mt-15 mt-lg-0'>
            <p className='custom-opacity contact-us-text mb-0 text-start text-lg-end w-100 me-lg-5 text-nowrap'>
              {FOOTER_DATA.CONTACT_US}
            </p>
            <div className='d-flex justify-content-between align-items-center mt-5 mt-sm-0'>
              <img
                alt='Email'
                className='mx-2 cursor-pointer'
                src={FINANCIAL_SECTION.IMAGES.EMAIL_ICON}
                onClick={handleOnClickMail}
              />
              <img
                alt='Reddit'
                className='mx-2 cursor-pointer'
                src={FINANCIAL_SECTION.IMAGES.REDDIT_ICON}
                onClick={() => setShowThankYouModal(true)}
              />
              <img
                alt='Discord'
                className='mx-2 cursor-pointer'
                src={FINANCIAL_SECTION.IMAGES.DISCORD_ICON}
                onClick={() => setShowThankYouModal(true)}
              />
            </div>
          </div> */}
        </div>
      </footer>
      {/* web view */}
      <div
        className={`position-relative d-none d-lg-block ${
          showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[13] ? 'd-none' : ''
        }`}
        id={sectionIds[13]}
      >
        <img alt='footer-img' src={FOOTER_DATA.IMAGES.FOOTER_BIG_IMG} />
        <div className='legal-notes-container position-absolute px-7'>
          <p className='paragraph-small fw-400 px-13'>{FOOTER_DATA.LEGAL_NOTES_FIRST_TITLE}</p>
          <p className='paragraph-x-small px-13'>
            {FOOTER_DATA.VIVIDLI_ABOUT_MONOLITHS}
          </p>
        </div>
        <div className='footer-md-legal-section pb-md-12 px-7'>
          <p className='paragraph-small fw-400 px-13 mt-4'>
            {FOOTER_DATA.LEGAL_NOTES_SECOND_TITLE}
          </p>
          <p className='paragraph-x-small px-13 position-relative z-index-1' style={{width: '93%'}}>
            {FOOTER_DATA.LEGAL_NOTES_PARAGRAPH}
          </p>
          {/* credit Onyxtec */}
          <div className='d-flex align-items-center pt-3 position-relative z-index-1'>
            <p className='paragraph-small fw-400 ps-13 pe-1 mb-0'>
              {FOOTER_DATA.ONYXTEC_CREDIT}
              <a href={process.env.REACT_APP_ONYXTEC_URL} target={'_blank'} rel='noreferrer'>
                {' '}
                {FOOTER_DATA.ONYXTEC}{' '}
                <img
                  alt='onyxtec-logo'
                  className='onyxtec-logo'
                  src={FOOTER_DATA.IMAGES.ONYXTEC_LOGO}
                />
              </a>
            </p>
          </div>
        </div>
        <img
          alt='footer-cloud-bottom'
          className='cloud-bottom position-absolute d-none d-xl-block'
          src={FOOTER_DATA.IMAGES.FOOTER_CLOUD_BOTTOM}
        />
      </div>
      {/* mobile view */}
      <div className='d-block d-lg-none'>
        <img alt='footer-img' className='w-100' src={FOOTER_DATA.IMAGES.FOOTER_MOB_MOUNTAINS} />
        <div className='footer-legal-section pb-12 px-7 px-md-10 px-lg-20'>
          <p className='paragraph-small fw-bold'>{FOOTER_DATA.LEGAL_NOTES_FIRST_TITLE}</p>
          <p className='paragraph-x-small'>
            {FOOTER_DATA.VIVIDLI_ABOUT_MONOLITHS}
          </p>
          <br></br>
          <p className='paragraph-small fw-bold'>{FOOTER_DATA.LEGAL_NOTES_SECOND_TITLE}</p>
          <p className='paragraph-x-small'>{FOOTER_DATA.LEGAL_NOTES_PARAGRAPH}</p>
          {/* credit OnyxTec */}
          <div className='d-flex align-items-center pt-3 position-relative z-index-1'>
            <p className='paragraph-small fw-400 pe-1 mb-0'>
              {FOOTER_DATA.ONYXTEC_CREDIT}
              <a href={process.env.REACT_APP_ONYXTEC_URL} target={'_blank'} rel='noreferrer'>
                {' '}
                {FOOTER_DATA.ONYXTEC}{' '}
                <img
                  alt='onyxtec-logo'
                  className='onyxtec-logo'
                  src={FOOTER_DATA.IMAGES.ONYXTEC_LOGO}
                />
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* modal for feature coming soon */}
      {/* <VerticallyCenteredModal
        show={showThankYouModal}
        onHide={() => setShowThankYouModal(false)}
        title={FEATURE_COMING_SOON.TITLE}
        message={FEATURE_COMING_SOON.MESSAGE}
      /> */}
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
