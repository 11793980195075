import {useState, useRef, useEffect} from 'react'
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {emitter} from '@marvelapp/react-ab-test'
import {toast} from 'react-toastify'
import TagManager from 'react-gtm-module'
import * as Yup from 'yup'
import {
  BottomLineModel,
  CompoundingGrowth,
  InvestmentChartProps,
  InvestmentFormModel,
  InvestmentTableModel,
} from '../helper/calculator/_model'
import {
  assets,
  contributionAndDividendsFrequency,
} from '../homeComponents/projections/components/ProjectionsConfig'
import {
  calculateBottomLineData,
  generateChartData,
  generateTableData,
} from '../helper/calculator/InvestmentCalculator'
import {toUSD, toUSDWithoutRoundOff} from '../helper/charts.helper'
import {ChartExperiment} from '../ABTest/_ABTestConfig'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import {TYPES_OF_ROR} from '../../../constants/AppConstants'
import {useAdvisorFormContext} from '../../context/AdvisorContext'
import {submitAdvisorForm} from '../advisor/core/_request'
import {useAdvisorsState} from '../advisor/components/AdvisorsUtils'

export function useProjectionsState() {
  // extracting useparams
  const {advisor_url} = useParams()
  const {assetsState} = useAdvisorsState()
  const location = useLocation()
  // Check if "arq" is in the URL
  const pathsToCheck = ['arq', 'advisor-demo1', 'advisor-demo2', 'waystone', 'arq/home']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))
  const isAghazInUrl = location.pathname.includes('aghaz/home')
  const isRealStateInUrl = location.pathname.includes('realestate/home')
  const isARQ_homeINURL =
    location.pathname.includes('arq/home') || location.pathname.includes('arq-mobile')

  // updating assets array for arq/home page
  if (isARQ_homeINURL && assets[assets.length - 1].label === 'Custom') {
    // extracting last index of assets array(assuming that last index is always a custom bar)
    const customAssetIndex = assets.length - 1
    // Update the value for the (Rate of Return) object with the label "Custom"
    assets[customAssetIndex].label = 'Google'
    assets[customAssetIndex].dropdownLabel = `(25%) Google`
    assets[customAssetIndex].customLabel = `25%`
    assets[customAssetIndex].value = 25
  }

  // destructuring useReceiverFormContext hook
  const {
    receiverFormDataObj,
    setReceiverURL,
    setPictoFutureValue,
    setTimeSeriesGraphDataObj,
    goalSettingBar,
    setGoalSettingBar,
    setIsProjectionsInputChanged,
    setProjectionsSentence,
  } = useReceiverFormContext()

  const {setIsFormUploading, setAdvisorURL} = useAdvisorFormContext()

  // useNavigation hook
  const navigate = useNavigate()

  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  //for goal setting
  const [maxDataValue, setMaxDataValue] = useState(0)

  // Define the validation schema using Yup
  const ProjectionsSchema = Yup.object().shape({
    initialDeposit: Yup.number()
      .min(0, 'Please enter an initial amount greater than or equal to $0')
      .max(10000000, 'Please enter an initial amount less than or equal to $10,000,000')
      .typeError('Please enter an initial amount greater than or equal to $0')
      .required('Please enter the starting amount'),
    contributions: Yup.number()
      .min(0, 'Please enter contributions greater than or equal to $0')
      .max(500000, 'Please enter contributions less than or equal to $500,000')
      .typeError('Please enter contributions amount greater than or equal to $0')
      .required('Please enter the contributions'),
    contributionFrequency: Yup.number().required('Please select the contribution frequency'),
    compoundingGrowth: Yup.number().required('Please select the rate of return'),
    numberOfYears: Yup.number()
      .min(1, 'Please enter a number of years greater than or equal to 1')
      .max(100, 'Please enter a number of years less than or equal to 100')
      .typeError('Please enter number of years greater than or equal to 1')
      .required('Please enter the number of years to grow'),
    ARQ_Flip_Gain: Yup.number()
      .min(0, 'Please enter gain profit amount greater than or equal to $0')
      .max(10000000, 'Please enter gain profit amount less than or equal to $10,000,000')
      .typeError('Please enter gain profit amount greater than or equal to $0'),
    ARQ_rental_income: Yup.number()
      .min(0, 'Please enter rental income amount greater than or equal to $0')
      .max(10000000, 'Please enter rental income less than or equal to $10,000,000')
      .typeError('Please enter rental income amount greater than or equal to $0'),
    ARQ_rental_contribution: Yup.number().required('Please select the contribution frequency'),
    ARQ_appreciation: Yup.number()
      .min(0, 'Please enter appreciation amount greater than or equal to $0')
      .max(10000000, 'Please enter appreciation less than or equal to $10,000,000')
      .typeError('Please enter appreciation amount greater than or equal to $0'),
    ARQ_property_worth: Yup.number()
      .min(1, "Please enter property's worth greater than or equal to $1")
      .max(10000000, "Please enter a property's worth greater than or equal to $10,000,000")
      .typeError("Please enter property's worth greater than or equal to $1")
      .required(),
    management_fee: Yup.number().min(1).max(100),
    goalSettingName: Yup.string().min(3).max(15),
    goalSettingValue: Yup.number().min(0),
  })
  // Define the initial form values
  const initialValues: InvestmentFormModel = {
    initialDeposit: receiverFormDataObj.starting_amount,
    contributions: receiverFormDataObj.contributions,
    contributionFrequency: receiverFormDataObj.contributions_frequency,
    compoundingGrowth: receiverFormDataObj.selected_rate_of_return_obj.id,
    numberOfYears: receiverFormDataObj.years_to_grow,
    ARQ_Flip_Gain: receiverFormDataObj.ARQ_Flip_Gain,
    ARQ_rental_income: receiverFormDataObj.ARQ_rental_income,
    ARQ_rental_contribution: receiverFormDataObj.ARQ_rental_contribution,
    ARQ_appreciation: receiverFormDataObj.ARQ_appreciation,
    ARQ_property_worth: receiverFormDataObj.ARQ_property_worth,
    management_fee: receiverFormDataObj.management_fee,
    goalSettingName: goalSettingBar.goalName,
    goalSettingValue: goalSettingBar.goalValue,
    rollOver: receiverFormDataObj.rolled_over_to_phase2,
    customer_actual: receiverFormDataObj.customer_actual,
    newStartingAmount: receiverFormDataObj.new_starting_amount,
    ARQ_Property_End_Value: receiverFormDataObj.property_worth_end_value,
    lump_sump_phase1_rent_received: receiverFormDataObj.lump_sump_phase1_rent_received,
  }
  const [chartData, setChartData] = useState<InvestmentChartProps[]>([])
  const [tableData, setTableData] = useState<InvestmentTableModel[]>([])
  const [isDataSet, setIsDataSet] = useState<Boolean>(true)

  const [sentence, setSentence] = useState<{
    initialDep: string
    contribution: string
    numberOfYears: any
    compoundingFreq: string | any
    compoundingGrowth: string | null
    futureValue: string
  }>()
  const [bottomLineChartData, setBottomLineChartData] = useState<BottomLineModel>()
  const [selectedRate, setSelectedRate] = useState<CompoundingGrowth>(
    receiverFormDataObj.selected_rate_of_return_obj
  )
  const isFirstRender = useRef(true)
  const initialFormValuesRef = useRef<InvestmentFormModel>(initialValues)
  const [trendingField, setTrendingField] = useState<string>('')
  const [changedFieldSequence, setChangedFieldSequence] = useState<string[]>([])

  useEffect(() => {
    updateChartTableSentence(initialValues, false)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      return
    }
    setSelectedRate(receiverFormDataObj.selected_rate_of_return_obj)
  }, [receiverFormDataObj.selected_rate_of_return_obj.id])

  useEffect(() => {
    if (isFirstRender.current) {
      return
    }
    if (
      formik.getFieldProps('compoundingGrowth').value == selectedRate.id &&
      selectedRate.id == assets.length - 1
    ) {
      updateChartTableSentence(formik.values, true)
      sendAnalytics(formik.values)
    }
  }, [selectedRate.dropdownLabel])

  const handleCompoundingGrowth = (data: any) => {
    return data.filter((item: any) => {
      // Keep all items except ARQ
      return item.type === TYPES_OF_ROR.TYPE_SIMPLE
    })
  }
  function getChartData(data: InvestmentFormModel) {
    const _chartData = generateChartData(
      data.initialDeposit,
      data.contributions,
      data.contributionFrequency,
      data.numberOfYears,
      isARQ_homeINURL || isRealStateInUrl
        ? assets.filter((asset) => [3, 5, 6, 7].includes(asset.id))
        : isAghazInUrl
        ? assets.filter((asset) => [2, 3, 7].includes(asset.id))
        : // : isRealStateInUrl
          // ? assets.filter((asset) => [3, 4, 20, 21].includes(asset.id))
          assets,
      // assets,
      data.ARQ_Flip_Gain,
      data.ARQ_rental_income,
      data.ARQ_rental_contribution,
      data.ARQ_appreciation,
      data.ARQ_property_worth,
      data.management_fee,
      data.management_fee > 0 ? true : false,
      data.rollOver,
      data.customer_actual,
      data.newStartingAmount,
      data.ARQ_Property_End_Value
    )
    const filteredData = _chartData.map((entry) => ({
      ...entry,
      contributions: entry.type === TYPES_OF_ROR.TYPE_SIMPLE ? entry.contributions : 0,
      ARQ_Flip_Gain:
        entry.type === TYPES_OF_ROR.TYPE_SIMPLE ||
        entry.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT ||
        entry.type === TYPES_OF_ROR.TYPE_RENTAL_INCOME_ONLY
          ? 0
          : entry.ARQ_Flip_Gain,
      ARQ_rental_income:
        entry.type === TYPES_OF_ROR.TYPE_SIMPLE || entry.type === TYPES_OF_ROR.TYPE_ARQ_FLIP
          ? 0
          : entry.ARQ_rental_income,
      ARQ_appreciation:
        entry.type === TYPES_OF_ROR.TYPE_AFTER_EXIT || entry.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL
          ? entry.ARQ_appreciation
          : 0,
    }))
    const barsToBeFilterOnIds = receiverFormDataObj.selected_bars

    const arrangedData = barsToBeFilterOnIds.map((index) => filteredData[index])
    // set chart data to by default filteredItems if the page if ARQ home page
    setChartData(
      advisor_url
        ? arrangedData
        : isARQ_homeINURL
        ? filteredData
        : handleCompoundingGrowth(filteredData)
    )
    return advisor_url
      ? arrangedData
      : isARQ_homeINURL || isRealStateInUrl
      ? filteredData
      : // : isRealStateInUrl
        // ? filteredData
        handleCompoundingGrowth(filteredData)
  }

  function getTableData(data: InvestmentFormModel) {
    const _tableData = generateTableData(
      data.initialDeposit,
      data.contributions,
      data.contributionFrequency,
      data.numberOfYears,
      selectedRate,
      data.ARQ_Flip_Gain,
      data.ARQ_property_worth,
      data.ARQ_appreciation,
      data.ARQ_rental_contribution,
      data.ARQ_rental_income,
      data.management_fee,
      data.rollOver,
      data.newStartingAmount,
      data.ARQ_Property_End_Value,
      data.customer_actual,
      data.management_fee > 0 ? true : false
    )
    // Use the map function to transform _tableData
    const transformedData = _tableData.map((tableData_obj) => ({
      ...tableData_obj,
      contributionsThisPeriod:
        selectedRate.type === TYPES_OF_ROR.TYPE_SIMPLE ? tableData_obj.contributionsThisPeriod : 0,
      flipGain:
        selectedRate.type === TYPES_OF_ROR.TYPE_SIMPLE ||
        selectedRate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT
          ? 0
          : tableData_obj.flipGain,
      rentalIncome:
        selectedRate.type === TYPES_OF_ROR.TYPE_SIMPLE ||
        selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP
          ? 0
          : tableData_obj.rentalIncome,
      appreciation:
        selectedRate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT ||
        selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL
          ? tableData_obj.appreciation
          : 0,
      OriginalARQValues: {
        proportionValue: tableData_obj.originalARQValues.proportionValue,
        fixAndFlip: tableData_obj.originalARQValues.fixAndFlip,
        rental: tableData_obj.originalARQValues.rental,
        appreciation: tableData_obj.originalARQValues.appreciation,
        management_fee: data.management_fee,
      },
      lastValues: {
        contributions: _tableData[_tableData.length - 1].cumulativeContributions,
        gain: _tableData[_tableData.length - 1].gain,
        yearOrMonth: _tableData[_tableData.length - 1].yearOrMonth,
      },
    }))

    // to show - in the repetitive rows in table
    const modifiedTableData = _tableData.map((tableDataObj, index) => {
      let flipGain: number | null = -1
      if (selectedRate.type === TYPES_OF_ROR.TYPE_SIMPLE) {
        flipGain = index === 0 ? 0 : -1
      } else if (selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
        flipGain = tableDataObj.flipGain
      } else if (
        selectedRate.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL ||
        selectedRate.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT
      ) {
        flipGain = index === 0 ? tableDataObj.flipGain : -1
      } else if (
        selectedRate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT &&
        index === _tableData.length - 1
      ) {
        flipGain = tableDataObj.flipGain
      }
      return {
        ...tableDataObj,
        initialDeposit: index === 0 ? tableDataObj.initialDeposit : -1,
        flipGain,
      }
    })
    setTableData(modifiedTableData)

    /* to bypass type error on setTimeSeriesGraphDataObj, 
	 emptyArray is used. So the requirement of same datatypes of 
	 setTimeSeriesGraphDataObj and transformedData is not violated. */
    const emptyArray: any = []
    setTimeSeriesGraphDataObj([...emptyArray, ...transformedData])
  }

  const generateSentence = (values: InvestmentFormModel, _chartData: InvestmentChartProps[]) => {
    const contributionFrequencyLabels = {
      52: 'week',
      24: 'monthly',
      12: 'month',
      4: 'quarter',
      2: 'yearly',
      1: 'year',
    }
    const selectedFrequency = contributionAndDividendsFrequency.find(
      (freq) => freq.value == values.contributionFrequency
    )
    const contributionFreqLabel = selectedFrequency
      ? contributionFrequencyLabels[
          selectedFrequency.value as keyof typeof contributionFrequencyLabels
        ]
      : ''
    const compoundingGrowth = selectedRate.dropdownLabel
    const futureValue = toUSD(
      _chartData.find(
        (investment) =>
          investment.label === selectedRate.label && investment.type === selectedRate.type
      )?.futureValue || 0
    )
    const _sentence = {
      startingAmount: toUSD(values.initialDeposit),
      contributions: toUSD(values.contributions),
      numberOfYears: values.numberOfYears,
      contributionFrequency: contributionFreqLabel && (
        <>
          {values.contributionFrequency == 24 || values.contributionFrequency == 2
            ? 'twice '
            : 'per '}
          <span className='pink-text fw-600'>{contributionFreqLabel}</span>
        </>
      ),
      rateOfReturn: compoundingGrowth,
      futureValue,
    }
    // setting future value for pictograph
    setPictoFutureValue(
      _chartData.find(
        (investment) =>
          investment.label === (selectedRate.label || selectedRate.dropdownLabel) &&
          investment.type === selectedRate.type
      )?.futureValue || 0
    )
    setProjectionsSentence(_sentence)
  }

  const getBottomLineChartData = (data: InvestmentFormModel) => {
    let _bottomLineData = calculateBottomLineData(
      data.initialDeposit,
      data.numberOfYears,
      selectedRate,
      data.ARQ_Flip_Gain,
      data.ARQ_property_worth,
      data.ARQ_appreciation,
      data.ARQ_rental_contribution,
      data.ARQ_rental_income,
      data.management_fee,
      data.rollOver,
      data.newStartingAmount,
      data.ARQ_Property_End_Value,
      data.customer_actual,
      data.management_fee > 0 ? true : false
    )
    setBottomLineChartData(_bottomLineData)
  }
  const getTrendingField = (_changedFieldSequence: string[]) => {
    const eachFieldCount: {[key: string]: number} = _changedFieldSequence.reduce(
      (count: {[key: string]: number}, item) => {
        count[item] = (count[item] || 0) + 1
        return count
      },
      {}
    )

    let _trendingField = ''
    let maxCount = 0

    for (const item in eachFieldCount) {
      if (eachFieldCount[item] > maxCount) {
        _trendingField = item
        maxCount = eachFieldCount[item]
      }
    }

    return _trendingField
  }

  const areValuesEqual = (values1: InvestmentFormModel, values2: InvestmentFormModel) => {
    const formKeys = Object.keys(values1) as Array<keyof InvestmentFormModel>
    for (const key of formKeys) {
      if (values1[key] != values2[key]) {
        return false // Values are not equal
      }
    }
    return true // All values are equal
  }

  const updateChartTableSentence = async (values: InvestmentFormModel, showBenchMarks: boolean) => {
    const _chartData = getChartData(values)
    setChartData(_chartData)
    setIsDataSet(false)

    getTableData(values)
    generateSentence(values, _chartData)
    setMaxDataValue(Math.max(...chartData.map((item) => item.futureValue)))
    getBottomLineChartData(values)
  }

  const sendAnalytics = (values: InvestmentFormModel) => {
    // Create the dataLayer object
    const dataLayer: {[key: string]: unknown} = {
      event: 'projections_form',
      gtm: {
        elementId: 'projection_form',
      },
    }
    // Initialize variables
    const _changedFieldSequence = [...changedFieldSequence]
    let _trendingField = ''
    const defaultValues: {[key: string]: unknown} = {}

    // Process form fields
    const formKeys = Object.keys(values) as Array<keyof InvestmentFormModel>

    formKeys.forEach((key) => {
      var valueToAdd
      var isChanged = values[key] !== initialFormValuesRef.current[key]
      if (key === 'compoundingGrowth') {
        if (isChanged) {
          valueToAdd = selectedRate.dropdownLabel
          defaultValues[`default_${key}`] = undefined
        } else {
          valueToAdd = undefined
          defaultValues[`default_${key}`] = selectedRate.dropdownLabel
        }
      } else if (key === 'contributionFrequency') {
        const selectedOption = contributionAndDividendsFrequency.find(
          (option) => option.value == values[key]
        )
        if (isChanged) {
          valueToAdd = selectedOption?.label
          defaultValues[`default_${key}`] = undefined
        } else {
          valueToAdd = undefined
          defaultValues[`default_${key}`] = selectedOption?.label
        }
      } else if (key === 'ARQ_rental_contribution') {
        const selectedOption = contributionAndDividendsFrequency.find(
          (option) => option.value == values[key]
        )
        if (isChanged) {
          valueToAdd = selectedOption?.label
          defaultValues[`default_${key}`] = undefined
        } else {
          valueToAdd = undefined
          defaultValues[`default_${key}`] = selectedOption?.label
        }
      } else {
        valueToAdd = isChanged ? values[key] : undefined
        defaultValues[`default_${key}`] = isChanged ? undefined : values[key]
      }

      dataLayer[key] = valueToAdd
      dataLayer['arq_advisor_url'] = advisor_url
        ? advisor_url
        : isArqInURL
        ? '/arq/home'
        : undefined
      if (valueToAdd !== undefined) {
        _changedFieldSequence.push(key)
      }
    })

    // Get the trending field
    _trendingField = getTrendingField(_changedFieldSequence)

    // Set version in dataLayer (production only)
    if (process.env.NODE_ENV === 'production') {
      const activeVariant = emitter.getActiveVariant(ChartExperiment.name)
      dataLayer['version'] = activeVariant === ChartExperiment.variantA ? 'version A' : 'version B'
    }

    // Update dataLayer with the changedFieldSequence, trendingField and default values
    dataLayer['changedFieldSequence'] = _changedFieldSequence
    dataLayer['trendingField'] = _trendingField
    dataLayer['default_values'] = defaultValues

    // Send dataLayer to TagManager
    TagManager.dataLayer({
      dataLayer: dataLayer,
    })

    // Update trendingField and changedFieldSequence in the component's state
    setTrendingField(_trendingField)
    setChangedFieldSequence(_changedFieldSequence)

    // Store current form values for future comparison
    initialFormValuesRef.current = values
  }

  const sendMeetAnalytics = (meetingLink: string) => {
    const dataLayer: {[key: string]: unknown} = {
      event: 'projections_form',
      gtm: {
        elementId: 'projection_form',
      },
    }
    const values = initialFormValuesRef.current
    // Initialize variables
    const _changedFieldSequence = [...changedFieldSequence]
    let _trendingField = ''
    const defaultValues: {[key: string]: unknown} = {}

    // Process form fields
    const formKeys = Object.keys(values) as Array<keyof InvestmentFormModel>

    formKeys.forEach((key) => {
      var valueToAdd
      if (key === 'compoundingGrowth') {
        valueToAdd = selectedRate.dropdownLabel
        defaultValues[`default_${key}`] = undefined
      } else if (key === 'contributionFrequency') {
        const selectedOption = contributionAndDividendsFrequency.find(
          (option) => option.value == values[key]
        )

        valueToAdd = selectedOption?.label
        defaultValues[`default_${key}`] = undefined
      } else if (key === 'ARQ_rental_contribution') {
        const selectedOption = contributionAndDividendsFrequency.find(
          (option) => option.value == values[key]
        )

        valueToAdd = selectedOption?.label
        defaultValues[`default_${key}`] = undefined
      } else {
        valueToAdd = values[key]
        defaultValues[`default_${key}`] = undefined
      }

      dataLayer[key] = valueToAdd
      dataLayer['arq_advisor_url'] = advisor_url
        ? advisor_url
        : isArqInURL
        ? '/arq/home'
        : undefined
      if (valueToAdd !== undefined) {
        _changedFieldSequence.push(key)
      }
    })

    // Get the trending field
    _trendingField = getTrendingField(_changedFieldSequence)

    // Set version in dataLayer (production only)
    if (process.env.NODE_ENV === 'production') {
      const activeVariant = emitter.getActiveVariant(ChartExperiment.name)
      dataLayer['version'] = activeVariant === ChartExperiment.variantA ? 'version A' : 'version B'
    }

    // Update dataLayer with the changedFieldSequence, trendingField and default values
    dataLayer['changedFieldSequence'] = _changedFieldSequence
    dataLayer['trendingField'] = _trendingField
    dataLayer['default_values'] = defaultValues
    dataLayer['meet_link'] = meetingLink
    // Send dataLayer to TagManager
    TagManager.dataLayer({
      dataLayer: dataLayer,
    })
  }

  const handleReplyButton = async (isMeet: boolean) => {
    // set form loading state = true
    setIsFormUploading(true)
    try {
      // handle submit advisor form
      const res = await submitAdvisorForm(
        receiverFormDataObj.starting_amount,
        receiverFormDataObj.contributions,
        receiverFormDataObj.contributions_frequency,
        receiverFormDataObj.selected_rate_of_return_obj.value,
        receiverFormDataObj.custom_investment_values,
        receiverFormDataObj.years_to_grow,
        // sending a dummy greetings message
        'hello',
        receiverFormDataObj.logo_url,
        // sending a dummy email
        'aniq@arqventures.com',
        receiverFormDataObj.ARQ_Flip_Gain,
        receiverFormDataObj.ARQ_rental_income,
        receiverFormDataObj.ARQ_rental_contribution,
        receiverFormDataObj.ARQ_appreciation,
        receiverFormDataObj.ARQ_property_worth,
        '', // notes field is for advisor only so sending null
        100 - receiverFormDataObj.management_fee,
        'arq', // redirectURLRoute is only for advisors
        receiverFormDataObj.selected_bars,
        // sending empty arry for past performance
        [],
        receiverFormDataObj.customer_actual,
        receiverFormDataObj.rolled_over_to_phase2,
        receiverFormDataObj.property_worth_end_value,
        receiverFormDataObj.lump_sump_phase1_rent_received,
        receiverFormDataObj.new_starting_amount
      )
      setAdvisorURL(res?.data?.URL)
      setIsFormUploading(false)

      // storing advisorURL into context hook
      setReceiverURL(res?.data?.URL)

      // navigating user to contact page
      if (!isMeet) navigate('/contact')

      let updatedReceiverSlug: string
      if (res?.data?.URL !== '' && res?.data?.URL !== undefined) {
        const relativeSlug = res?.data?.URL?.substring(res?.data?.URL?.indexOf('arq/') + 4)
        updatedReceiverSlug = `${process.env.REACT_APP_ARQ_CREATE_PAGE_URL}${relativeSlug}`
        //sending to analytics if meeting link exists
        isMeet && sendMeetAnalytics(updatedReceiverSlug)
      }
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
      setIsFormUploading(false)
    }
  }

  const handleSubmit = async (values: InvestmentFormModel) => {
    const commonValues: InvestmentFormModel = {
      initialDeposit: receiverFormDataObj.starting_amount,
      contributions: receiverFormDataObj.contributions,
      contributionFrequency: receiverFormDataObj.contributions_frequency,
      numberOfYears: receiverFormDataObj.years_to_grow,
      compoundingGrowth: receiverFormDataObj.selected_rate_of_return_obj.id,
      ARQ_Flip_Gain: receiverFormDataObj.ARQ_Flip_Gain,
      ARQ_rental_income: receiverFormDataObj.ARQ_rental_income,
      ARQ_rental_contribution: receiverFormDataObj.ARQ_rental_contribution,
      ARQ_appreciation: receiverFormDataObj.ARQ_appreciation,
      ARQ_property_worth: receiverFormDataObj.ARQ_property_worth,
      management_fee: receiverFormDataObj.management_fee,
      goalSettingName: goalSettingBar.goalName,
      goalSettingValue: goalSettingBar.goalValue,
      rollOver: receiverFormDataObj.rolled_over_to_phase2,
      customer_actual: receiverFormDataObj.customer_actual,
      newStartingAmount: receiverFormDataObj.new_starting_amount,
      ARQ_Property_End_Value: receiverFormDataObj.property_worth_end_value,
      lump_sump_phase1_rent_received: receiverFormDataObj.lump_sump_phase1_rent_received,
    }
    if (areValuesEqual(values, commonValues)) {
      return
    }
    updateChartTableSentence(values, true)

    sendAnalytics(values)
    // setting goal bar context values
    setGoalSettingBar({
      goalName: formik.values.goalSettingName,
      goalValue: formik.values.goalSettingValue,
    })
    // if (
    //   goalSettingBar.goalValue !== 0.0 &&
    //   formik.getFieldProps('goalSettingValue').value > maxDataValue * 1.2
    // ) {
    //   console.log('if')
    //   setGoalSettingBar({
    //     goalName: formik.values.goalSettingName,
    //     goalValue: maxDataValue * 1.2,
    //   })
    // } else {
    //   console.log('else')
    //   setGoalSettingBar({
    //     goalName: formik.values.goalSettingName,
    //     goalValue: formik.values.goalSettingValue,
    //   })
    // }
  }

  const formik = useFormik<InvestmentFormModel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: ProjectionsSchema,
    onSubmit: handleSubmit,
  })

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const handleCompoundingFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('contributionFrequency', value)
  }

  const handleARQRentalFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('ARQ_rental_contribution', value)
  }
  const handleAssetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value // Use the selected id
    const selectedOptionData = assets.find((option) => option.id.toString() === selectedId)
    if (selectedOptionData) {
      let adjustedObj = {
        ...selectedOptionData,
        dropdownLabel: isRealStateInUrl
          ? selectedOptionData.dropdownLabel === 'ARQ After Exit'
            ? 'Rental & Sale'
            : selectedOptionData.dropdownLabel === 'ARQ Before Exit'
            ? 'Rental Income Only'
            : selectedOptionData.dropdownLabel
          : selectedOptionData.dropdownLabel,
      }

      setSelectedRate(adjustedObj)
      formik.setFieldValue('compoundingGrowth', selectedId)
    }
  }

  return {
    maxDataValue,
    tableData,
    chartData,
    isDataSet,
    //isDataChanged,
    sentence,
    selectedRate,
    isFirstRender,
    initialFormValuesRef,
    trendingField,
    changedFieldSequence,
    bottomLineChartData,
    getChartData,
    generateSentence,
    handleSubmit,
    handleKeyPress,
    handleCompoundingFreqChange,
    handleARQRentalFreqChange,
    handleAssetChange,
    formik,
    setSentence,
    setTableData,
    handleReplyButton,
    setChartData,
    //  setIsProjectionsInputChanged,
  }
}
