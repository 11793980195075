import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from '../../../helper/charts.helper'

interface CustomTooltipProps extends TooltipProps<number, string> {}

const IBLOCComposedChartTooltip: React.FC<CustomTooltipProps> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
  const index = (data?.Year - 3) as number // Access the index from the payload

  // Return the JSX for the tooltip
  return (
    <div className='custom-tooltip sbloc-tooltip-container'>
      <div className='d-flex justify-content-between align-items-center mt-3'>
        <p className='label-bold paragraph-x-small mb-0'>Year#{data?.Year}</p>
      </div>

      {/* Total Cash Value */}
      <div className='d-flex justify-content-between align-items-center '>
        <p className='label-bold mb-0'>Cash Balance : </p>
        <p className='label-bold paragraph-x-small mb-0'>{toUSD(data?.CashBalance)}</p>
      </div>

      {/* CashIn */}
      <div className='d-flex justify-content-between align-items-center'>
        <p className='label-bold paragraph-x-small mb-0 label-initial-deposit mt-5'>Cash In:</p>
        <p className='label-bold paragraph-x-small mb-0 label-initial-deposit mt-5'>
          +{toUSD(data?.CashIn)}
        </p>
      </div>

      {/* CashOut */}
      <div className='d-flex justify-content-between align-items-center'>
        <p className='label-bold paragraph-x-small mb-0 label-re-invest'>Cash Value Increase </p>
        <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
          +{toUSD(data?.DividendIncreaseInTotalCashValue)}
        </p>
      </div>

      {/* IBLOCBalance */}
      <div className='d-flex justify-content-between align-items-center'>
        <p className='label-bold paragraph-x-small mb-0 label-cashOut'>Cash Out/Policy Loan: </p>
        <p className='label-bold paragraph-x-small mb-0 label-cashOut'>-{toUSD(data?.CashOut)}</p>
      </div>
      {/* IBLOCBalance */}
      <div className='d-flex justify-content-between align-items-center'>
        <p className='label-bold paragraph-x-small mb-0 label-debt-on-policy-loan'>
          Debt on Policy Loan:{' '}
        </p>
        <p className='label-bold paragraph-x-small mb-0 label-debt-on-policy-loan'>
          {isFinite(data.PLInterest)
            ? data.PLInterest > 0
              ? `-${toUSD(data.PLInterest)}`
              : `${toUSD(data.PLInterest)}`
            : '0$'}
        </p>
      </div>

      {/* Cash on Cash ROI */}
      <div className='d-flex justify-content-between align-items-center'>
        <p className='mb-0 mt-5'>=Net change:</p>
        <p className={` mb-0 mt-5 ${data.net > 0 ? 'label-profit' : 'label-cashOut'}`}>
          {isFinite(data.net)
            ? data.net > 0
              ? `+${toUSD(data.net)}`
              : `${toUSD(data.net)}`
            : '0$'}
        </p>
      </div>
    </div>
  )
}

export default IBLOCComposedChartTooltip
