import {useEffect, useState, useRef, memo, Fragment} from 'react'
import {motion, useTransform, useScroll} from 'framer-motion'
import {useLocation, useParams, useNavigate} from 'react-router-dom'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {INPUT_BOX_TOOLTIPS, TYPES_OF_ROR, sectionIds} from '../../../../../constants/AppConstants'
import CustomOverlayTooltip from './CustomOverlayTooltip'
import {useProjectionsState} from '../../../utils/commonUtils'
import {assets, contributionAndDividendsFrequency, defaultAsset} from './ProjectionsConfig'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {useAdvisorFormContext} from '../../../../context/AdvisorContext'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'
import ProjectionSlider from '../../../../../components/projectionSlider/ProjectionSlider'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {usePastPerformanceState} from '../../PastPerformance/components/PastPerformanceUtils'
import {useAdvisorsState} from '../../../advisor/components/AdvisorsUtils'
import {useAuth} from '../../../../../modules/auth'
import {addProjectionInputs} from '../../../advisor/core/_request'
import {toast} from 'react-toastify'
import {Button} from 'react-bootstrap'
import {PROJECTION_INPUTS_SECTION} from '../../../../../constants/AppConstants'
type ProjectionInputProps = {
  refProp: React.RefObject<HTMLDivElement>
  onTableDataChange: (newData: any[]) => void
  onChartDataChange: (newData: any[]) => void
  onPastChartDataChange: (newData: any[]) => void
  onPastChartDataOldValuesChange: (newData: any[]) => void
}
const notifyInputsSaved = (msg: string) => toast.success(msg)

const ProjectionInput: React.FC<ProjectionInputProps> = ({
  refProp,
  onTableDataChange,
  onChartDataChange,
  onPastChartDataChange,
  onPastChartDataOldValuesChange,
}) => {
  // destructuring useReceiverFormContext hook
  const {
    setReceiverFormDataObj,
    receiverFormDataObj,
    isSticky,
    setIsSticky,
    distanceFromTop,
    setDistanceFromTop,
  } = useReceiverFormContext()
  const {isCustomerActual, setIsCustomerActual, rolledOverToPhase2, setRolledOverToPhase2} =
    useAdvisorsState()

  //extracting current loggedIn user
  const {currentUser} = useAuth()
  // extracting useparams\
  const {advisor_url} = useParams()
  const location = useLocation()
  const formRef = useRef<HTMLFormElement | null>(null)
  useEffect(() => {
    const formElement = formRef.current

    const handleScroll = () => {
      if (formElement) {
        const boundingBox = formElement.getBoundingClientRect()
        setDistanceFromTop(boundingBox.top)
        if (boundingBox.top <= 50 && boundingBox.top > 0) {
          setIsSticky(true)
        }
        if (boundingBox.top > 400) {
          setIsSticky(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  // Check if "arq" is in the URL
  const pathsToCheck = ['arq', 'advisor-demo1', 'advisor-demo2', 'waystone']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))
  const isWaystoneInURL = location.pathname.includes('waystone')
  const isRealEstateInUrl = location.pathname.includes('realestate/home')
  const isARQ_MobileINURL = location.pathname.includes('arq-mobile')

  // screen width custom hook
  const screenWidth = useScreenWidth()

  // parallax animation stuff
  const targetRef = useRef(null)
  const {scrollYProgress} = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })
  const opacityOfFirstContainer = useTransform(scrollYProgress, [0, 0.2, 0.4], [0, 0, 1])
  const backgroundImageY = useTransform(scrollYProgress, [0, 0.5], ['-40%', '0%'])

  const {isFormUploading} = useAdvisorFormContext()

  // state hooks
  const [showModal, setShowModal] = useState(false)
  const [isExtraContributions, setIsExtraContributions] = useState(false)
  const [isGoalSettingBar, setIsGoalSettingBar] = useState(false)
  const [isARQFields, setIsARQFields] = useState(false)

  // destructuring custom(useProjectionsState) state
  const {
    maxDataValue,
    tableData,
    chartData,
    isFirstRender,
    selectedRate,
    handleKeyPress,
    handleCompoundingFreqChange,
    handleARQRentalFreqChange,
    handleAssetChange,
    formik,
  } = useProjectionsState()

  const {pastChartData, pastChartDataOldValues} = usePastPerformanceState()

  const {setIsProjectionsInputChanged} = useReceiverFormContext()
  const filteredArray = assets.filter((asset) => {
    return chartData.some((filteredItem) => asset?.label === filteredItem?.label)
  })

  // useEffect for formik values except compoundingGrowth
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const timeoutId = setTimeout(() => {
      setReceiverFormDataObj((prevState) => ({
        ...prevState,
        starting_amount: formik.values.initialDeposit,
        contributions: formik.values.contributions,
        contributions_frequency: formik.values.contributionFrequency,
        selected_rate_of_return_obj:
          assets.find((asset) => asset.id === selectedRate.id) || assets[defaultAsset],
        years_to_grow: formik.values.numberOfYears,
        ARQ_Flip_Gain: formik.values.ARQ_Flip_Gain,
        ARQ_rental_income: formik.values.ARQ_rental_income,
        ARQ_rental_contribution: formik.values.ARQ_rental_contribution,
        ARQ_appreciation: formik.values.ARQ_appreciation,
        ARQ_property_worth: formik.values.ARQ_property_worth,
        management_fee: formik.values.management_fee,
        selected_bars: receiverFormDataObj.selected_bars,
        lump_sump_phase1_rent_received: formik.values.lump_sump_phase1_rent_received,
        customer_actual: formik.values.customer_actual,
        rolled_over_to_phase2: formik.values.rollOver,
        property_worth_end_value: formik.values.ARQ_Property_End_Value,
        new_starting_amount: formik.values.newStartingAmount,
      }))

      formik.handleSubmit()
      setIsProjectionsInputChanged(true)
    }, 100)

    // Cleanup the timeout to avoid side effects when the component unmounts or the dependency changes
    return () => clearTimeout(timeoutId)
  }, [
    formik.values.initialDeposit,
    formik.values.contributions,
    formik.values.contributionFrequency,
    formik.values.numberOfYears,
    formik.values.ARQ_Flip_Gain,
    formik.values.ARQ_rental_income,
    formik.values.ARQ_rental_contribution,
    formik.values.ARQ_appreciation,
    formik.values.ARQ_property_worth,
    formik.values.management_fee,
    receiverFormDataObj.selected_bars,
    formik.values.ARQ_Property_End_Value,
    formik.values.customer_actual,
    formik.values.lump_sump_phase1_rent_received,
    formik.values.newStartingAmount,
    formik.values.rollOver,
    formik.values.goalSettingName,
    formik.values.goalSettingValue,
  ])

  // useEffect for compoundingGrowth
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const timeoutId = setTimeout(() => {
      setReceiverFormDataObj((prevState) => ({
        ...prevState,
        selected_rate_of_return_obj: selectedRate || assets[defaultAsset],
      }))

      formik.handleSubmit()
    }, 100)

    return () => clearTimeout(timeoutId)
  }, [formik.values.compoundingGrowth])
  //function to save inputs in db
  function saveProjectionInputs() {
    if (currentUser ? currentUser.id : false) {
      addProjectionInputs(
        currentUser ? currentUser.id : 0,
        formik.values.initialDeposit,
        formik.values.contributions,
        formik.values.contributionFrequency,
        receiverFormDataObj.selected_rate_of_return_obj.id,
        formik.values.numberOfYears,
        formik.values.ARQ_Flip_Gain,
        formik.values.ARQ_rental_income,
        formik.values.ARQ_rental_contribution,
        formik.values.ARQ_appreciation,
        formik.values.ARQ_property_worth,
        formik.values.management_fee,
        formik.values.goalSettingName ? formik.values.goalSettingName : 'not set',
        formik.values.goalSettingValue,
        isArqInURL ? true : false,
        false, //rolloVer is by default false for every chart
        formik.values.customer_actual,
        formik.values.newStartingAmount,
        formik.values.lump_sump_phase1_rent_received,
        formik.values.ARQ_Property_End_Value
      )
    }
  }
  useEffect(() => {
    onTableDataChange(tableData)
  }, [tableData])

  // handle RoR/comppounding growth
  const handleCompoundingGrowth = () => {
    return advisor_url || isArqInURL || isRealEstateInUrl
      ? filteredArray.map((option, index) => {
          return (
            option.label !== 'Custom' && (
              <option className='px-2' key={`${option.dropdownLabel}-${index}`} value={option.id}>
                {isRealEstateInUrl && option.dropdownLabel === 'ARQ Before Exit'
                  ? 'Rental Income Only'
                  : isRealEstateInUrl && option.dropdownLabel === 'ARQ After Exit'
                  ? 'Rental & Sale'
                  : option.dropdownLabel}
              </option>
            )
          )
        })
      : filteredArray
          .filter((item) => {
            return item.type === TYPES_OF_ROR.TYPE_SIMPLE
          })
          .map((option, index) => {
            return (
              <Fragment key={index}>
                {option.label !== 'Custom' && (
                  <option
                    className='px-2'
                    key={`${option.dropdownLabel}-${index}`}
                    value={option.id}
                  >
                    {option.dropdownLabel}
                  </option>
                )}
              </Fragment>
            )
          })
  }

  useEffect(() => {
    onChartDataChange(chartData)
  }, [chartData])

  useEffect(() => {
    onPastChartDataChange(pastChartData)
  }, [pastChartData])

  useEffect(() => {
    onPastChartDataOldValuesChange(pastChartDataOldValues)
  }, [pastChartDataOldValues])

  return (
    <>
      {/* <motion.section ref={targetRef} id={sectionIds[0]}>
        <motion.div
          style={screenWidth < 767 ? {opacity: opacityOfFirstContainer, y: backgroundImageY} : {}}
          ref={refProp}
        > */}
      <div
        id={sectionIds[0]}
        className={`text-select-transparent mx-lg-18 mx-md-0 py-3 form ${
          isSticky && screenWidth > 768 ? 'sticky ' : ''
        } ${distanceFromTop > 700 && screenWidth > 768 ? 'mt-200' : ''}`}
      >
        {/* projections form */}
        {!isARQ_MobileINURL ? (
          <>
            <h1
              className={`text-center my-2 mb-6 ${
                distanceFromTop > 1500 || distanceFromTop < 300 ? 'd-none' : ''
              }`}
            >
              {PROJECTION_INPUTS_SECTION.HEADING}
            </h1>
            <p
              className={`text-center pt-0 pb-2 mb-20 ${
                distanceFromTop > 1500 || distanceFromTop < 300 ? 'd-none' : ''
              }`}
            >
              {isWaystoneInURL
                ? PROJECTION_INPUTS_SECTION.WAYSTONE_MESSAGE
                : PROJECTION_INPUTS_SECTION.MESSAGE}
            </p>
          </>
        ) : null}

        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className='form'
          id='projection-form'
          ref={formRef}
        >
          <div className='row m-0'>
            {/* Starting Amount field */}
            <div className='col-5 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Starting Amount</p>
                <CustomOverlayTooltip
                  content={INPUT_BOX_TOOLTIPS.STARTING_AMOUNT}
                  className='tooltip-logo'
                />
              </div>
              <div
                className={`input-container d-flex justify-content-center align-items-center ${
                  formik.touched.initialDeposit && formik.errors.initialDeposit
                    ? 'error-border'
                    : ''
                }`}
              >
                <CustomInputField
                  id='initial-deposit'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=' e.g 1000.00'
                  decimalsLimit={2}
                  maxLength={8}
                  defaultValue={formik.values.initialDeposit}
                  onValueChange={(value: any) => {
                    const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                    formik.setFieldValue('initialDeposit', parsedValue)
                  }}
                  onKeyDown={handleKeyPress}
                  value={formik.values.initialDeposit}
                />
              </div>
              <ProjectionSlider
                value={formik.values.initialDeposit}
                maxValue={100000}
                onRangeChange={(value: any) =>
                  formik.setFieldValue('initialDeposit', parseInt(value!))
                }
              />
            </div>

            {/* Rate of Return/compounding growth dropdown */}
            <div className='col-6 col-sm-5 col-md-3 fv-row projections-select mb-0 mb-lg-0 ps-0 pe-0'>
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Rate of Return</p>
                <CustomOverlayTooltip
                  content={INPUT_BOX_TOOLTIPS.RATE_OF_RETURN}
                  className='tooltip-logo'
                />
              </div>
              <select
                id='compounding-growth'
                className='form-select dropdown'
                {...formik.getFieldProps(`compoundingGrowth`)}
                onChange={handleAssetChange}
              >
                {handleCompoundingGrowth()}
              </select>
            </div>
            {/* Years to Grow field */}
            <div
              className={`col-5 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0 ${
                screenWidth < 768 ? '' : 'mx-2'
              }`}
            >
              <div className='d-flex align-items-baseline'>
                <p className='ps-1 m-0 fields-label'>Years to Grow</p>
                <CustomOverlayTooltip
                  content={INPUT_BOX_TOOLTIPS.YEARS_TO_GROW}
                  className='tooltip-logo'
                />
              </div>
              <div
                className={`input-container d-flex justify-content-center align-items-center ${
                  formik.touched.numberOfYears && formik.errors.numberOfYears ? 'error-border' : ''
                }`}
              >
                <p className='m-0 currency-symbol d-none'>$</p>
                <input
                  id='number-of-years'
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='e.g 10'
                  {...formik.getFieldProps('numberOfYears')}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <ProjectionSlider
                value={formik.values.numberOfYears}
                minValue={1}
                maxValue={20}
                isMoney={false}
                onRangeChange={(value: any) =>
                  formik.setFieldValue('numberOfYears', parseInt(value!))
                }
              />
            </div>

            {isExtraContributions ? (
              <>
                {/* Contributions field */}
                <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 p-0 ps-md-2'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Extra Contributions</p>
                    <CustomOverlayTooltip
                      content={INPUT_BOX_TOOLTIPS.CONTRIBUTIONS}
                      className='tooltip-logo'
                    />
                  </div>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center ${
                      formik.touched.contributions && formik.errors.contributions
                        ? 'error-border'
                        : ''
                    }`}
                  >
                    <CustomInputField
                      id='contributions'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' e.g 10.00'
                      decimalsLimit={2}
                      maxLength={6}
                      defaultValue={formik.values.contributions}
                      onValueChange={(value: any) => {
                        const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                        formik.setFieldValue('contributions', parsedValue)
                      }}
                      onKeyDown={handleKeyPress}
                      value={formik.values.contributions}
                    />
                  </div>
                  <ProjectionSlider
                    value={formik.values.contributions}
                    maxValue={10000}
                    onRangeChange={(value: any) =>
                      formik.setFieldValue('contributions', parseInt(value!))
                    }
                  />
                </div>
                {/* Contributions frequency dropdown */}
                <div className='col-5 col-md-2 fv-row projections-select mb-0 mb-lg-0 pe-0 pe-md-2'>
                  <p className='ps-1 m-0 fields-label time-frame'>Time Frame</p>
                  <select
                    id='compounding-frequency'
                    className={`form-select dropdown ${screenWidth < 768 ? 'ms-n2' : ''}`}
                    {...formik.getFieldProps('contributionFrequency')}
                    onChange={handleCompoundingFreqChange}
                  >
                    {contributionAndDividendsFrequency.map((option) => {
                      return (
                        <option className='px-2' key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </>
            ) : (
              <>
                <div className='col-7 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0 mt-1'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-5 m-0 fields-label'>Extra contributions</p>
                    <CustomOverlayTooltip
                      content={INPUT_BOX_TOOLTIPS.EXTRA_CONTRIBUTIONS}
                      className='tooltip-logo'
                    />
                  </div>
                  <div className='d-flex align-items-baseline'>
                    <div
                      className='btn btn-sm btn-icon btn-active-color-primary btn-color-dark ms-n1'
                      onClick={() => {
                        setIsExtraContributions(true)
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen035.svg'
                        className='svg-icon-2x'
                      />
                    </div>
                    <p className='teal-text paragraph-small'>Make contributions</p>
                  </div>
                </div>
              </>
            )}
            {isGoalSettingBar ? (
              <div className={`${screenWidth < 768 ? 'mt-1' : ''} row m-0 p-0`}>
                {/* goal name */}
                <div className='col-5 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Goal name</p>
                    <CustomOverlayTooltip
                      content={INPUT_BOX_TOOLTIPS.GOAL_NAME}
                      className='tooltip-logo'
                    />
                  </div>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center ${
                      formik.touched.goalSettingName && formik.errors.goalSettingName
                        ? 'error-border'
                        : ''
                    }`}
                  >
                    <input
                      id='goal-name'
                      type='text'
                      maxLength={15}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='set your goal'
                      {...formik.getFieldProps('goalSettingName')}
                      onKeyDown={handleKeyPress}
                    />
                  </div>
                </div>

                {/* goal value */}
                <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 p-0 ps-2'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Goal value</p>
                    <CustomOverlayTooltip
                      content={INPUT_BOX_TOOLTIPS.GOAL_VALUE}
                      className='tooltip-logo'
                    />
                  </div>
                  <div
                    className='input-container d-flex justify-content-center align-items-center'
                    // className={`input-container d-flex justify-content-center align-items-center ${
                    //   !isFirstRender.current &&
                    //   formik.getFieldProps('goalSettingValue').value >
                    //     Math.max(...chartData.map((item) => item.futureValue))
                    //     ? 'error-border'
                    //     : ''
                    // }`}
                  >
                    <CustomInputField
                      id='goal-value'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' e.g 1000.00'
                      decimalsLimit={2}
                      // maxLength={8}
                      // defaultValue={formik.values.ARQ_appreciation}
                      onValueChange={(value: any) => {
                        const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                        formik.setFieldValue('goalSettingValue', parsedValue)
                      }}
                      onKeyDown={handleKeyPress}
                      value={formik.values.goalSettingValue}
                    />
                  </div>
                  <ProjectionSlider
                    value={formik.values.goalSettingValue}
                    minValue={0}
                    maxValue={Math.max(...chartData.map((item) => item.futureValue))}
                    onRangeChange={(value: any) =>
                      formik.setFieldValue('goalSettingValue', parseInt(value!))
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`${
                    screenWidth < 768 ? 'mt-2' : ''
                  } col-12 col-md-2 fv-row mb-0 mb-lg-0 p-0`}
                >
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-5 m-0 fields-label'>Goal</p>
                    <CustomOverlayTooltip
                      content={INPUT_BOX_TOOLTIPS.SET_GOAL}
                      className='tooltip-logo'
                    />
                  </div>
                  <div className='d-flex align-items-baseline'>
                    <div
                      className='btn btn-sm btn-icon btn-active-color-primary btn-color-dark'
                      onClick={() => {
                        setIsGoalSettingBar(true)
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen035.svg'
                        className='svg-icon-2x'
                      />
                    </div>
                    <p className='teal-text paragraph-small'>Set a goal</p>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* error messages */}
          <div className='text-center mt-2'>
            {formik.touched.initialDeposit && formik.errors.initialDeposit ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'> * {formik.errors.initialDeposit}</div>
              </div>
            ) : null}
            {formik.touched.contributions && formik.errors.contributions ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'> * {formik.errors.contributions}</div>
              </div>
            ) : null}
            {formik.touched.numberOfYears && formik.errors.numberOfYears ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'> * {formik.errors.numberOfYears}</div>
              </div>
            ) : null}
            {formik.touched.goalSettingName && formik.errors.goalSettingName ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'> * {formik.errors.goalSettingName}</div>
              </div>
            ) : null}
            {/* {!isFirstRender.current &&
                formik.getFieldProps('goalSettingValue').value >
                  Math.max(...chartData.map((item) => item.futureValue)) ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * value must be less than {maxDataValue}</div>
                  </div>
                ) : null} */}
          </div>
          {/* ARQ fields */}
          {selectedRate.type !== TYPES_OF_ROR.TYPE_SIMPLE ? (
            isARQFields ? (
              <div className='row m-0'>
                {/* Gain (Flip Profit) field */}

                {selectedRate.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT && (
                  <div className='col-5 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                    <div className='d-flex align-items-baseline'>
                      <p className='ps-1 m-0 fields-label'>Gain (Flip Profit)</p>
                    </div>
                    <div
                      className={`input-container d-flex justify-content-center align-items-center ${
                        formik.touched.ARQ_Flip_Gain && formik.errors.ARQ_Flip_Gain
                          ? 'error-border'
                          : ''
                      }`}
                    >
                      <CustomInputField
                        className='form-control form-control-lg form-control-solid'
                        placeholder=' e.g 1000.00'
                        decimalsLimit={2}
                        maxLength={8}
                        defaultValue={formik.values.ARQ_Flip_Gain}
                        onValueChange={(value: any) => {
                          const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                          formik.setFieldValue('ARQ_Flip_Gain', parsedValue)
                        }}
                        onKeyDown={handleKeyPress}
                        value={formik.values.ARQ_Flip_Gain}
                      />
                    </div>
                    <ProjectionSlider
                      value={formik.values.ARQ_Flip_Gain}
                      maxValue={500000}
                      onRangeChange={(value: any) =>
                        formik.setFieldValue('ARQ_Flip_Gain', parseInt(value!))
                      }
                    />
                  </div>
                )}
                {selectedRate.type === TYPES_OF_ROR.TYPE_AFTER_EXIT && (
                  <div className='col-6 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0 mt-3'>
                    <CustomOverlayTooltip
                      content={INPUT_BOX_TOOLTIPS.Customer_Actual}
                      className='tooltip-logo'
                    />
                    <div className='fd-flex align-items-baseline'>
                      <input
                        type='checkbox'
                        id='yourCheckboxId'
                        checked={formik.values.customer_actual}
                        onChange={() => {
                          setIsCustomerActual(!isCustomerActual)
                          formik.setFieldValue('customer_actual', !isCustomerActual ? true : false)
                        }}
                      />
                      <label className='mx-2'>Customer's Actual </label>
                    </div>
                  </div>
                )}

                {selectedRate.type !== TYPES_OF_ROR.TYPE_ARQ_FLIP && (
                  <>
                    {/* Gain (Rental Income) field */}
                    <div className='col-5 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>Gain (Rental Income)</p>
                      </div>
                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.ARQ_rental_income && formik.errors.ARQ_rental_income
                            ? 'error-border'
                            : ''
                        }`}
                      >
                        <CustomInputField
                          id='ARQ_rental_income'
                          className='form-control form-control-lg form-control-solid'
                          placeholder=' e.g 1000.00'
                          decimalsLimit={2}
                          maxLength={8}
                          defaultValue={formik.values.ARQ_rental_income}
                          onValueChange={(value: any) => {
                            const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                            formik.setFieldValue('ARQ_rental_income', parsedValue)
                          }}
                          onKeyDown={handleKeyPress}
                          value={formik.values.ARQ_rental_income}
                        />
                      </div>
                      <ProjectionSlider
                        value={formik.values.ARQ_rental_income}
                        maxValue={50000}
                        onRangeChange={(value: any) =>
                          formik.setFieldValue('ARQ_rental_income', parseInt(value!))
                        }
                      />
                    </div>
                    {/* Time Frame dropdown */}
                    <div
                      className={`col-6 col-md-3 col-lg-2 fv-row projections-select mb-2 mb-lg-0 pe-0 pe-md-4 ps-0 ${
                        screenWidth < 768 ? 'mt-8' : ''
                      }`}
                    >
                      <p className='ps-1 m-0 fields-label time-frame d-none d-sm-flex'>
                        Time Frame
                      </p>
                      <select
                        id='compounding-frequency'
                        className='form-select dropdown'
                        {...formik.getFieldProps('ARQ_rental_contribution')}
                        onChange={handleARQRentalFreqChange}
                      >
                        {contributionAndDividendsFrequency.map((option) => {
                          return (
                            <option className='px-2' key={option.label} value={option.value}>
                              {option.label}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </>
                )}
                {/* Property's Worth field */}

                <div className='col-5 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Property start value</p>
                  </div>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center ${
                      formik.touched.ARQ_property_worth && formik.errors.ARQ_property_worth
                        ? 'error-border'
                        : ''
                    }`}
                  >
                    <CustomInputField
                      id='ARQ_property_worth'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' e.g 1000.00'
                      decimalsLimit={2}
                      maxLength={8}
                      defaultValue={formik.values.ARQ_property_worth}
                      onValueChange={(value: any) => {
                        const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                        formik.setFieldValue('ARQ_property_worth', parsedValue)
                      }}
                      onKeyDown={handleKeyPress}
                      value={formik.values.ARQ_property_worth}
                    />
                  </div>
                  <ProjectionSlider
                    value={formik.values.ARQ_property_worth}
                    maxValue={5000000}
                    onRangeChange={(value: any) =>
                      formik.setFieldValue('ARQ_property_worth', parseInt(value!))
                    }
                  />
                </div>

                {/* management fee field */}
                {selectedRate.type !== TYPES_OF_ROR.TYPE_ARQ_FLIP &&
                  selectedRate.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL && (
                    <div className='col-6 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                      <div className='d-flex align-items-baseline'>
                        <p className='ps-1 m-0 fields-label'>Distribution</p>
                      </div>

                      <div
                        className={`input-container d-flex justify-content-center align-items-center ${
                          formik.touched.management_fee && formik.errors.management_fee
                            ? 'error-border'
                            : ''
                        }`}
                      >
                        <span className='m-0 currency-symbol me-1'>%</span>
                        <input
                          id='management_fee'
                          type='number'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='e.g 10'
                          min={0}
                          max={100}
                          {...formik.getFieldProps('management_fee')}
                          onKeyDown={handleKeyPress}
                        />
                      </div>
                      <ProjectionSlider
                        value={formik.values.management_fee}
                        maxValue={100}
                        minValue={1}
                        isMoney={false}
                        onRangeChange={(value: any) =>
                          formik.setFieldValue('management_fee', parseInt(value!))
                        }
                      />
                    </div>
                  )}
                {/* new Starting Amount field */}
                <div className={`col-5 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0 `}>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>New Starting Amount</p>
                  </div>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center ${
                      formik.touched.newStartingAmount && formik.errors.newStartingAmount
                        ? 'error-border'
                        : ''
                    }`}
                  >
                    <CustomInputField
                      id='initial-deposit'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' e.g 1000.00'
                      decimalsLimit={2}
                      maxLength={8}
                      defaultValue={formik.values.newStartingAmount}
                      onValueChange={(value: any) => {
                        const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                        formik.setFieldValue('newStartingAmount', parsedValue)
                      }}
                      onKeyDown={handleKeyPress}
                      value={formik.values.newStartingAmount}
                    />
                  </div>
                  <ProjectionSlider
                    value={formik.values.newStartingAmount}
                    maxValue={100000}
                    onRangeChange={(value: any) =>
                      formik.setFieldValue('newStartingAmount', parseInt(value!))
                    }
                  />
                </div>

                {/* Lump Sum Phase 1 Rent Received */}
                <div className='col-6 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Lump Sum Rent Received</p>
                  </div>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center ${
                      formik.touched.lump_sump_phase1_rent_received &&
                      formik.errors.lump_sump_phase1_rent_received
                        ? 'error-border'
                        : ''
                    }`}
                  >
                    <CustomInputField
                      id='initial-deposit'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' e.g 1000.00'
                      decimalsLimit={2}
                      maxLength={8}
                      defaultValue={formik.values.lump_sump_phase1_rent_received}
                      onValueChange={(value: any) => {
                        const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                        formik.setFieldValue('lump_sump_phase1_rent_received', parsedValue)
                      }}
                      onKeyDown={handleKeyPress}
                      value={formik.values.lump_sump_phase1_rent_received}
                    />
                  </div>
                  <ProjectionSlider
                    value={formik.values.lump_sump_phase1_rent_received}
                    maxValue={100000}
                    onRangeChange={(value: any) =>
                      formik.setFieldValue('lump_sump_phase1_rent_received', parseInt(value!))
                    }
                  />
                </div>

                {/* property worth end value*/}
                <div className='col-5 col-sm-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                  <div className='d-flex align-items-baseline'>
                    <p className='ps-1 m-0 fields-label'>Property End Value</p>
                  </div>
                  <div
                    className={`input-container d-flex justify-content-center align-items-center ${
                      formik.touched.ARQ_Property_End_Value && formik.errors.ARQ_Property_End_Value
                        ? 'error-border'
                        : ''
                    }`}
                  >
                    <CustomInputField
                      id='initial-deposit'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' e.g 1000.00'
                      decimalsLimit={2}
                      maxLength={8}
                      defaultValue={formik.values.ARQ_Property_End_Value}
                      onValueChange={(value: any) => {
                        const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                        formik.setFieldValue('ARQ_Property_End_Value', parsedValue)
                      }}
                      onKeyDown={handleKeyPress}
                      value={formik.values.ARQ_Property_End_Value}
                    />
                  </div>
                  <ProjectionSlider
                    value={formik.values.ARQ_Property_End_Value}
                    maxValue={100000}
                    onRangeChange={(value: any) =>
                      formik.setFieldValue('ARQ_Property_End_Value', parseInt(value!))
                    }
                  />
                </div>

                <div className='text-center mt-2'>
                  {formik.touched.ARQ_Flip_Gain && formik.errors.ARQ_Flip_Gain ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'> * {formik.errors.ARQ_Flip_Gain}</div>
                    </div>
                  ) : null}
                  {formik.touched.ARQ_rental_income && formik.errors.ARQ_rental_income ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'> * {formik.errors.ARQ_rental_income}</div>
                    </div>
                  ) : null}
                  {formik.touched.ARQ_appreciation && formik.errors.ARQ_appreciation ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'> * {formik.errors.ARQ_appreciation}</div>
                    </div>
                  ) : null}
                  {formik.touched.ARQ_property_worth && formik.errors.ARQ_property_worth ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'> * {formik.errors.ARQ_property_worth}</div>
                    </div>
                  ) : null}
                  {formik.touched.ARQ_Property_End_Value && formik.errors.ARQ_Property_End_Value ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'> * {formik.errors.ARQ_Property_End_Value}</div>
                    </div>
                  ) : null}
                  {formik.touched.management_fee && formik.errors.management_fee ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'> * {formik.errors.management_fee}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <>
                <div className='col-5 col-sm-6 col-md-4 fv-row mb-0 mb-lg-0 ps-0'>
                  <div className='d-flex align-items-baseline'>
                    <div
                      className='btn btn-sm btn-icon btn-active-color-primary btn-color-dark'
                      onClick={() => {
                        setIsARQFields(true)
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen035.svg'
                        className='svg-icon-2x'
                      />
                    </div>
                    <p className='teal-text paragraph-small'>
                      {isRealEstateInUrl ? 'Real Estate Settings' : 'ARQ settings'}
                    </p>
                    <CustomOverlayTooltip
                      content={
                        isRealEstateInUrl
                          ? INPUT_BOX_TOOLTIPS.RENTAL_FIELDS
                          : INPUT_BOX_TOOLTIPS.ARQ_FIELDS
                      }
                      className='tooltip-logo'
                    />
                  </div>
                </div>
              </>
            )
          ) : null}
          {isARQFields ? (
            <div className='col-5 col-sm-6 col-md-4 fv-row mb-0 mb-lg-0 ps-0'>
              <div className='d-flex align-items-baseline'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary btn-color-dark'
                  onClick={() => {
                    setIsARQFields(false)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon-2x' />
                </div>
                {isRealEstateInUrl ? 'Real Estate Settings' : 'ARQ settings'}
                <CustomOverlayTooltip
                  content={
                    isRealEstateInUrl
                      ? INPUT_BOX_TOOLTIPS.RENTAL_FIELDS
                      : INPUT_BOX_TOOLTIPS.ARQ_FIELDS
                  }
                  className='tooltip-logo'
                />
              </div>
            </div>
          ) : null}
          {currentUser ? (
            <Button
              className='custom-primary-button my-5'
              onClick={() => {
                saveProjectionInputs()
                notifyInputsSaved('Your Inputs are saved for later Use!')
              }}
            >
              Save Inputs
            </Button>
          ) : null}
        </form>
      </div>
      {/* </motion.div>
      </motion.section> */}
    </>
  )
}

export default memo(ProjectionInput)
