export interface IDataRow {
  //static columns and able to paste
  Year: number
  Age: number
  BaseContractPremium: number
  FPRContractPremium: number
  APPUAPremium: number
  TotalPremium: number
  TotalCashValue: number
  IncreaseInTotalCashValue: number
  TotalDeathBenefit: number
  Dividend: number
  DividendTotalCashValue: number
  DividendIncreaseInTotalCashValue: number
  DeathBenefitWithoutDividends: number
  DividendTotalDeathBenefit: number
  // calculated columns
  CashIn: number
  APR: number
  CashOut: number
  IBLOCBalance: number
  IBLOCBalanceforBar: number
  TotalCashValueCalculated: number
  CashBalance: number
}
const IBLOCDefault: any[] = [
  {
    Year: 2020 + 3,
    Age: 42,
    BaseContractPremium: 0,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 0,
    TotalCashValue: 248659,
    IncreaseInTotalCashValue: 0,
    TotalDeathBenefit: 10265337,
    Dividend: 9495,
    DividendTotalCashValue: 258154,
    DividendIncreaseInTotalCashValue: 0,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10274832,
  },
  {
    Year: 2020 + 4,
    Age: 43,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 0,
    TotalPremium: 63816,
    TotalCashValue: 307462,
    IncreaseInTotalCashValue: 58803,
    TotalDeathBenefit: 10265337,
    Dividend: 13306,
    DividendTotalCashValue: 331195,
    DividendIncreaseInTotalCashValue: 73041,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10278643,
  },
  {
    Year: 2020 + 5,
    Age: 44,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 172368,
    TotalPremium: 236184,
    TotalCashValue: 545105,
    IncreaseInTotalCashValue: 237643,
    TotalDeathBenefit: 10265337,
    Dividend: 17202,
    DividendTotalCashValue: 587460,
    DividendIncreaseInTotalCashValue: 256265,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10282539,
  },
  {
    Year: 2020 + 6,
    Age: 45,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 700450,
    IncreaseInTotalCashValue: 155345,
    TotalDeathBenefit: 10265337,
    Dividend: 20325,
    DividendTotalCashValue: 765257,
    DividendIncreaseInTotalCashValue: 177797,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10285662,
  },
  {
    Year: 2020 + 7,
    Age: 46,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 862508,
    IncreaseInTotalCashValue: 162058,
    TotalDeathBenefit: 10265337,
    Dividend: 23662,
    DividendTotalCashValue: 953966,
    DividendIncreaseInTotalCashValue: 188710,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10288999,
  },
  {
    Year: 2020 + 8,
    Age: 47,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1031173,
    IncreaseInTotalCashValue: 168665,
    TotalDeathBenefit: 10265337,
    Dividend: 27393,
    DividendTotalCashValue: 1154082,
    DividendIncreaseInTotalCashValue: 200116,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10292730,
  },
  {
    Year: 2020 + 9,
    Age: 48,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1206531,
    IncreaseInTotalCashValue: 175358,
    TotalDeathBenefit: 10265337,
    Dividend: 31281,
    DividendTotalCashValue: 1366014,
    DividendIncreaseInTotalCashValue: 211932,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10296618,
  },
  {
    Year: 2020 + 10,
    Age: 49,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1388882,
    IncreaseInTotalCashValue: 182352,
    TotalDeathBenefit: 10265337,
    Dividend: 35447,
    DividendTotalCashValue: 1590573,
    DividendIncreaseInTotalCashValue: 224559,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10300784,
  },
  {
    Year: 2020 + 11,
    Age: 50,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1576807,
    IncreaseInTotalCashValue: 187925,
    TotalDeathBenefit: 10265337,
    Dividend: 39922,
    DividendTotalCashValue: 1826876,
    DividendIncreaseInTotalCashValue: 236303,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10305259,
  },
  {
    Year: 2020 + 12,
    Age: 51,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1771775,
    IncreaseInTotalCashValue: 194967,
    TotalDeathBenefit: 10265337,
    Dividend: 44797,
    DividendTotalCashValue: 2077049,
    DividendIncreaseInTotalCashValue: 250172,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10310134,
  },
  {
    Year: 2020 + 13,
    Age: 52,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 1973880,
    IncreaseInTotalCashValue: 202105,
    TotalDeathBenefit: 10265337,
    Dividend: 50138,
    DividendTotalCashValue: 2341985,
    DividendIncreaseInTotalCashValue: 264937,
    DeathBenefitWithoutDividends: 10265337,
    DividendTotalDeathBenefit: 10315520,
  },
  {
    Year: 2020 + 14,
    Age: 53,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 2183085,
    IncreaseInTotalCashValue: 209204,
    TotalDeathBenefit: 10265337,
    Dividend: 56322,
    DividendTotalCashValue: 2622022,
    DividendIncreaseInTotalCashValue: 280036,
    DeathBenefitWithoutDividends: 10739191,
    DividendTotalDeathBenefit: 10795513,
  },
  {
    Year: 2020 + 15,
    Age: 54,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 2399372,
    IncreaseInTotalCashValue: 216288,
    TotalDeathBenefit: 10265337,
    Dividend: 63293,
    DividendTotalCashValue: 2918005,
    DividendIncreaseInTotalCashValue: 295983,
    DeathBenefitWithoutDividends: 11296013,
    DividendTotalDeathBenefit: 11359306,
  },
  {
    Year: 2020 + 16,
    Age: 55,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 2618746,
    IncreaseInTotalCashValue: 219374,
    TotalDeathBenefit: 10265337,
    Dividend: 70432,
    DividendTotalCashValue: 3226243,
    DividendIncreaseInTotalCashValue: 308239,
    DeathBenefitWithoutDividends: 11857063,
    DividendTotalDeathBenefit: 11927495,
  },
  {
    Year: 2020 + 17,
    Age: 56,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 2845020,
    IncreaseInTotalCashValue: 226274,
    TotalDeathBenefit: 10467073,
    Dividend: 77956,
    DividendTotalCashValue: 3551551,
    DividendIncreaseInTotalCashValue: 325307,
    DeathBenefitWithoutDividends: 12422118,
    DividendTotalDeathBenefit: 12500073,
  },
  {
    Year: 2020 + 18,
    Age: 57,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 3078292,
    IncreaseInTotalCashValue: 233273,
    TotalDeathBenefit: 10794118,
    Dividend: 85674,
    DividendTotalCashValue: 3894846,
    DividendIncreaseInTotalCashValue: 343296,
    DeathBenefitWithoutDividends: 12991626,
    DividendTotalDeathBenefit: 13077300,
  },
  {
    Year: 2020 + 19,
    Age: 58,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 3318928,
    IncreaseInTotalCashValue: 240636,
    TotalDeathBenefit: 11110627,
    Dividend: 93538,
    DividendTotalCashValue: 4257020,
    DividendIncreaseInTotalCashValue: 362173,
    DeathBenefitWithoutDividends: 13565366,
    DividendTotalDeathBenefit: 13658904,
  },
  {
    Year: 2020 + 20,
    Age: 59,
    BaseContractPremium: 39316,
    FPRContractPremium: 24500,
    APPUAPremium: 86184,
    TotalPremium: 150000,
    TotalCashValue: 3567177,
    IncreaseInTotalCashValue: 248249,
    TotalDeathBenefit: 11415934,
    Dividend: 101622,
    DividendTotalCashValue: 4638908,
    DividendIncreaseInTotalCashValue: 381888,
    DeathBenefitWithoutDividends: 14142946,
    DividendTotalDeathBenefit: 14244567,
  },
  {
    Year: 2020 + 21,
    Age: 60,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 3714510,
    IncreaseInTotalCashValue: 147333,
    TotalDeathBenefit: 11415934,
    Dividend: 107925,
    DividendTotalCashValue: 4930593,
    DividendIncreaseInTotalCashValue: 291685,
    DeathBenefitWithoutDividends: 14428607,
    DividendTotalDeathBenefit: 14536533,
  },
  {
    Year: 2020 + 22,
    Age: 61,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 3866127,
    IncreaseInTotalCashValue: 151617,
    TotalDeathBenefit: 11415934,
    Dividend: 114436,
    DividendTotalCashValue: 5237307,
    DividendIncreaseInTotalCashValue: 307190,
    DeathBenefitWithoutDividends: 14722017,
    DividendTotalDeathBenefit: 14836453,
  },
  {
    Year: 2020 + 23,
    Age: 62,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4022004,
    IncreaseInTotalCashValue: 155877,
    TotalDeathBenefit: 11415934,
    Dividend: 121618,
    DividendTotalCashValue: 5561417,
    DividendIncreaseInTotalCashValue: 323634,
    DeathBenefitWithoutDividends: 15022948,
    DividendTotalDeathBenefit: 15144566,
  },
  {
    Year: 2020 + 24,
    Age: 63,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4181964,
    IncreaseInTotalCashValue: 159960,
    TotalDeathBenefit: 11415934,
    Dividend: 129395,
    DividendTotalCashValue: 5902181,
    DividendIncreaseInTotalCashValue: 340764,
    DeathBenefitWithoutDividends: 15332357,
    DividendTotalDeathBenefit: 15461752,
  },
  {
    Year: 2020 + 25,
    Age: 64,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4345929,
    IncreaseInTotalCashValue: 163966,
    TotalDeathBenefit: 11415934,
    Dividend: 137763,
    DividendTotalCashValue: 6260889,
    DividendIncreaseInTotalCashValue: 358718,
    DeathBenefitWithoutDividends: 15650912,
    DividendTotalDeathBenefit: 15788676,
  },
  {
    Year: 2020 + 26,
    Age: 65,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4513910,
    IncreaseInTotalCashValue: 167981,
    TotalDeathBenefit: 11415934,
    Dividend: 146633,
    DividendTotalCashValue: 6638410,
    DividendIncreaseInTotalCashValue: 377511,
    DeathBenefitWithoutDividends: 15979194,
    DividendTotalDeathBenefit: 16125827,
  },
  {
    Year: 2020 + 27,
    Age: 66,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4685911,
    IncreaseInTotalCashValue: 172001,
    TotalDeathBenefit: 11415934,
    Dividend: 155306,
    DividendTotalCashValue: 7034903,
    DividendIncreaseInTotalCashValue: 396493,
    DeathBenefitWithoutDividends: 16317501,
    DividendTotalDeathBenefit: 16472806,
  },
  {
    Year: 2020 + 28,
    Age: 67,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 4862106,
    IncreaseInTotalCashValue: 176195,
    TotalDeathBenefit: 11415934,
    Dividend: 164482,
    DividendTotalCashValue: 7451474,
    DividendIncreaseInTotalCashValue: 416570,
    DeathBenefitWithoutDividends: 16664515,
    DividendTotalDeathBenefit: 16828997,
  },
  {
    Year: 2020 + 29,
    Age: 68,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 5042565,
    IncreaseInTotalCashValue: 180459,
    TotalDeathBenefit: 11415934,
    Dividend: 174320,
    DividendTotalCashValue: 7889253,
    DividendIncreaseInTotalCashValue: 437779,
    DeathBenefitWithoutDividends: 17020530,
    DividendTotalDeathBenefit: 17194850,
  },
  {
    Year: 2020 + 30,
    Age: 69,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 5227383,
    IncreaseInTotalCashValue: 184819,
    TotalDeathBenefit: 11415934,
    Dividend: 184301,
    DividendTotalCashValue: 8348918,
    DividendIncreaseInTotalCashValue: 458666,
    DeathBenefitWithoutDividends: 17386121,
    DividendTotalDeathBenefit: 17570423,
  },
  {
    Year: 2020 + 31,
    Age: 70,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 5416451,
    IncreaseInTotalCashValue: 189067,
    TotalDeathBenefit: 11415934,
    Dividend: 195054,
    DividendTotalCashValue: 8838371,
    DividendIncreaseInTotalCashValue: 482553,
    DeathBenefitWithoutDividends: 17760730,
    DividendTotalDeathBenefit: 17955783,
  },
  {
    Year: 2020 + 32,
    Age: 71,
    BaseContractPremium: 39316,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 39316,
    TotalCashValue: 5609543,
    IncreaseInTotalCashValue: 193093,
    TotalDeathBenefit: 11415934,
    Dividend: 206299,
    DividendTotalCashValue: 9337493,
    DividendIncreaseInTotalCashValue: 506022,
    DeathBenefitWithoutDividends: 18145074,
    DividendTotalDeathBenefit: 18351373,
  },
]

const IBLOCDefault2: any[] = [
  {
    Year: 2020 + 3,
    Age: 39,
    BaseContractPremium: 0,
    FPRContractPremium: 0,
    APPUAPremium: 0,
    TotalPremium: 0,
    TotalCashValue: 123938,
    IncreaseInTotalCashValue: 0,
    TotalDeathBenefit: 5057695,
    Dividend: 4541,
    DividendTotalCashValue: 128478,
    DividendIncreaseInTotalCashValue: 0,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5062236,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 0,
    IBLOCBalance: 0,
    IBLOCBalanceforBar: -0,
    TotalCashValueCalculated: 128478,
    CashBalance: 128478,
  },
  {
    Year: 2020 + 4,
    Age: 40,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 0,
    TotalPremium: 31897,
    TotalCashValue: 152366,
    IncreaseInTotalCashValue: 28429,
    TotalDeathBenefit: 5057695,
    Dividend: 6582,
    DividendTotalCashValue: 164052,
    DividendIncreaseInTotalCashValue: 35574,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5064277,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 31897,
    IBLOCBalance: 33810,
    IBLOCBalanceforBar: -33810,
    TotalCashValueCalculated: 164052,
    CashBalance: 130241,
  },
  {
    Year: 2020 + 5,
    Age: 41,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 86206,
    TotalPremium: 118103,
    TotalCashValue: 269913,
    IncreaseInTotalCashValue: 117546,
    TotalDeathBenefit: 5057695,
    Dividend: 8860,
    DividendTotalCashValue: 291252,
    DividendIncreaseInTotalCashValue: 127200,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5066555,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 118103,
    IBLOCBalance: 161028,
    IBLOCBalanceforBar: -161028,
    TotalCashValueCalculated: 291252,
    CashBalance: 130223,
  },
  {
    Year: 2020 + 6,
    Age: 42,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 43103,
    TotalPremium: 75000,
    TotalCashValue: 345974,
    IncreaseInTotalCashValue: 76061,
    TotalDeathBenefit: 5057695,
    Dividend: 10785,
    DividendTotalCashValue: 379272,
    DividendIncreaseInTotalCashValue: 88021,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5068400,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 75000,
    IBLOCBalance: 250190,
    IBLOCBalanceforBar: -250190,
    TotalCashValueCalculated: 379272,
    CashBalance: 129081,
  },
  {
    Year: 2020 + 7,
    Age: 43,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 43103,
    TotalPremium: 75000,
    TotalCashValue: 424872,
    IncreaseInTotalCashValue: 78999,
    TotalDeathBenefit: 5057695,
    Dividend: 12658,
    DividendTotalCashValue: 472477,
    DividendIncreaseInTotalCashValue: 93205,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5070353,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 75000,
    IBLOCBalance: 344701,
    IBLOCBalanceforBar: -344701,
    TotalCashValueCalculated: 472477,
    CashBalance: 127775,
  },
  {
    Year: 2020 + 8,
    Age: 44,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 43103,
    TotalPremium: 75000,
    TotalCashValue: 506790,
    IncreaseInTotalCashValue: 81918,
    TotalDeathBenefit: 5057695,
    Dividend: 14721,
    DividendTotalCashValue: 571332,
    DividendIncreaseInTotalCashValue: 98854,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5072416,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 75000,
    IBLOCBalance: 444883,
    IBLOCBalanceforBar: -444883,
    TotalCashValueCalculated: 571332,
    CashBalance: 126448,
  },
  {
    Year: 2020 + 9,
    Age: 45,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 43103,
    TotalPremium: 75000,
    TotalCashValue: 591851,
    IncreaseInTotalCashValue: 85061,
    TotalDeathBenefit: 5057695,
    Dividend: 17004,
    DividendTotalCashValue: 676286,
    DividendIncreaseInTotalCashValue: 104955,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5074595,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 75000,
    IBLOCBalance: 551076,
    IBLOCBalanceforBar: -551076,
    TotalCashValueCalculated: 676286,
    CashBalance: 125209,
  },
  {
    Year: 2020 + 10,
    Age: 46,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 43103,
    TotalPremium: 75000,
    TotalCashValue: 680394,
    IncreaseInTotalCashValue: 88543,
    TotalDeathBenefit: 5057695,
    Dividend: 19345,
    DividendTotalCashValue: 787846,
    DividendIncreaseInTotalCashValue: 111560,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5076690,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 75000,
    IBLOCBalance: 663641,
    IBLOCBalanceforBar: -663641,
    TotalCashValueCalculated: 787846,
    CashBalance: 124204,
  },
  {
    Year: 2020 + 11,
    Age: 47,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 43103,
    TotalPremium: 75000,
    TotalCashValue: 771716,
    IncreaseInTotalCashValue: 91322,
    TotalDeathBenefit: 5057695,
    Dividend: 21968,
    DividendTotalCashValue: 905731,
    DividendIncreaseInTotalCashValue: 117885,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5079663,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 75000,
    IBLOCBalance: 782960,
    IBLOCBalanceforBar: -782960,
    TotalCashValueCalculated: 905731,
    CashBalance: 122770,
  },
  {
    Year: 2020 + 12,
    Age: 48,
    BaseContractPremium: 19253,
    FPRContractPremium: 12644,
    APPUAPremium: 43103,
    TotalPremium: 75000,
    TotalCashValue: 866669,
    IncreaseInTotalCashValue: 94953,
    TotalDeathBenefit: 5057695,
    Dividend: 24458,
    DividendTotalCashValue: 1030804,
    DividendIncreaseInTotalCashValue: 125073,
    DeathBenefitWithoutDividends: 5057695,
    DividendTotalDeathBenefit: 5082153,
    // calculated Columns
    CashIn: 0,
    APR: 6,
    CashOut: 75000,
    IBLOCBalance: 909437,
    IBLOCBalanceforBar: -909437,
    TotalCashValueCalculated: 1030804,
    CashBalance: 121366,
  },
]

export const calculateIBLOCBalance = (cashIn: any, Q: any, cashOut: any, iblocBalance: any) => {
  if (cashIn > iblocBalance + cashOut) {
    return 0
  } else {
    return (1 + Q / 100) * (cashOut + iblocBalance - cashIn)
  }
}
export const IBLOCDefaultData = generateData(IBLOCDefault)
export const IBLOCDefaultData2 = generateData(IBLOCDefault2)

function generateData(dataArray: any[]) {
  let givenRor = 7 / 100

  let tempArray = []
  for (let i = 0; i < dataArray.length; i++) {
    let tempObj = {...dataArray[i]}
    switch (true) {
      case i === 0:
        tempObj['APR'] = 6
        break
      case i === 1:
        tempObj['APR'] = 5.3
        break
      case i === 2:
        tempObj['APR'] = 5
        break
      case i >= 3 && i <= 17:
        tempObj['APR'] = 5
        break
      default:
        tempObj['APR'] = 5
        break
    }
    tempObj['CashIn'] = 0
    // tempObj['APR'] = 6
    tempObj['CashOut'] = i === 0 ? 100000 : tempObj.TotalPremium
    tempObj['IBLOCBalance'] =
      i === 0
        ? 1024.9 + (1 + tempObj.APR / 100) * tempObj.CashOut
        : calculateIBLOCBalance(
            tempObj.CashIn,
            tempObj.APR,
            tempObj.CashOut,
            tempArray[i - 1].IBLOCBalance
          )
    tempObj['IBLOCBalanceforBar'] = tempObj.IBLOCBalance * -1
    tempObj['TotalCashValueCalculated'] = tempObj.DividendTotalCashValue
    tempObj['CashBalance'] =
      tempObj.TotalCashValueCalculated -
      tempObj.IBLOCBalance +
      tempObj.CashIn +
      (i === 0 ? 826.46 : 0)
    tempObj['CvGrowthYoY'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempArray[i - 1].TotalCashValueCalculated) /
            tempArray[i - 1].TotalCashValueCalculated) *
          100
    tempObj['TotalCvGrowthLTD'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempArray[0].TotalCashValueCalculated) /
            tempArray[0].TotalCashValueCalculated) *
          100
    tempObj['CashBalanceGrowth'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempArray[i - 1].CashBalance) / tempArray[i - 1].CashBalance) *
          100
    tempObj['CashBalanceGrowthLTD'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempArray[0].CashBalance) / tempArray[0].CashBalance) * 100
    tempObj['TotalCashPutInSoFar'] =
      i === 0 ? 0 : tempObj.CashIn + tempArray[i - 1].TotalCashPutInSoFar
    tempObj['CashOnCashROI'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempObj.TotalCashPutInSoFar) /
            tempObj.TotalCashPutInSoFar) *
          100
    tempObj['TotalCashOpportunity'] =
      i === 0
        ? tempObj.TotalCashPutInSoFar * Math.pow(1 + givenRor, 2) + tempObj.CashIn * (1 + givenRor)
        : (tempObj.CashIn + tempArray[i - 1].TotalCashOpportunity) * (1 + givenRor)

    tempObj['TCOOROI'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashOpportunity - tempObj.TotalCashPutInSoFar) /
            tempObj.TotalCashPutInSoFar) *
          100
    tempObj['TCVDelta'] = i === 0 ? 0 : tempObj.TotalCashValue - tempArray[i - 1].TotalCashValue
    tempObj['CBDelta'] = i === 0 ? 0 : tempObj.CashBalance - tempArray[i - 1].CashBalance
    let cummulativeSumOfCashOutAndPLInterest =
      getTotalSumOfCashOut(i, tempArray, 'CashOut', tempObj.CashOut) +
      getTotalSumOfPLInterest(i, tempArray, 'PLInterest')
    tempObj['PLInterest'] =
      i === 0
        ? tempObj.IBLOCBalance - tempObj.CashOut
        : tempObj.IBLOCBalance - cummulativeSumOfCashOutAndPLInterest
    tempObj['IncreaseInCashBalance'] =
      i === 0 ? 0 : tempObj.CashBalance - tempArray[i - 1].CashBalance
    tempObj['cashInForComposedChart'] = tempObj.CashBalance + tempObj.CashIn
    tempObj['DividendIncreaseInTotalCashValueForComposedChart'] =
      tempObj.DividendIncreaseInTotalCashValue
    tempObj['alwaysPositivePLInterestForBars'] = Math.abs(tempObj.PLInterest)
    tempObj['negativeCashOut'] = -1 * tempObj.CashOut
    tempObj['CashBalanceBenchmark'] =
      Math.abs(tempObj.CashBalance) + Math.abs(tempObj.PLInterest) + Math.abs(tempObj.CashOut)
    tempObj['yearlyChangeInTotalCashValue'] =
      i == 0 ? 0 : tempObj['DividendTotalCashValue'] - tempArray[i - 1].DividendTotalCashValue
    tempObj['DCVfromPremiums'] = tempObj.TotalPremium
    tempObj['DCVfromDividend'] = tempObj.Dividend
    tempObj['DCVfromIRR'] =
      i == 0
        ? 0
        : tempObj.yearlyChangeInTotalCashValue -
          tempObj.DCVfromPremiums -
          tempArray[i - 1].DCVfromDividend
    tempObj['CashValueIRR'] =
      i === 0
        ? 0
        : ((tempArray[i - 1]['DCVfromDividend'] + tempObj['DCVfromIRR']) /
            getTotalSumOfPLInterest(i, tempArray, 'TotalPremium', true, tempObj.TotalPremium)) *
          100
    tempObj['CashValueIRRwoDivs'] =
      i === 0
        ? 0
        : (tempObj['DCVfromIRR'] /
            getTotalSumOfPLInterest(i, tempArray, 'TotalPremium', true, tempObj.TotalPremium)) *
          100
    tempObj['net'] =
      i === 0
        ? 0
        : tempObj.CashIn === 0
        ? tempObj.DividendIncreaseInTotalCashValue -
          (tempObj.IBLOCBalance - tempArray[i - 1].IBLOCBalance)
        : tempObj.DividendIncreaseInTotalCashValue - tempObj.CashIn
    tempArray.push(tempObj) // Add the modified object to the tempArray
  }
  return tempArray
}
function getTotalSumOfCashOut(
  currentIndex: number,
  dataArray: any[],
  keyToSum: string,
  currentCashOut: number
) {
  // Sum all the rows in tempArray that have already been processed (up to currentIndex)
  let sum = 0
  for (let i = 0; i < currentIndex; i++) {
    sum += dataArray[i][keyToSum]
  }
  return sum + currentCashOut // Add the current CashOut value as in your original code
}

// Function to calculate the total sum of PLInterest from the fixed starting row to the previous row
function getTotalSumOfPLInterest(
  currentIndex: number,
  dataArray: any[],
  keyToSum: string,
  useCurrentIndex = false,
  currentValue = 0
) {
  // Sum all the rows in tempArray that have already been processed (up to currentIndex-1)
  if (currentIndex === 0) return 0 // If the index is 0, there are no previous rows to sum
  let sum = 0
  for (let i = 0; i < currentIndex; i++) {
    if (i === 0 && useCurrentIndex) {
      sum += dataArray[i][keyToSum] + currentValue
    }
    sum += dataArray[i][keyToSum]
  }
  return sum
}
