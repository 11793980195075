import React, {useState, useEffect, useRef} from 'react'
import {MapContainer, TileLayer, Marker, Popup, Polyline, useMap, useMapEvents} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import {FaSearchPlus, FaSearchMinus, FaSearch, FaLock, FaLockOpen, FaCopy} from 'react-icons/fa'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import {sendAnalytics, sendToSheets} from './mapUtils'
import CopyToClipboard from 'react-copy-to-clipboard'
import {KTSVG} from '../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import domtoimage from 'dom-to-image-more'
import AutocompleteField from './AutoCompleteField'
import useScreenWidth from '../../modules/hooks/useScreenWidth'
// ORS API key (replace with your actual API key)
const ORS_API_KEY = '5b3ce3597851110001cf6248f45d38fdae8d46b487b1d2de330ffa1b'
const notifySuccess = (msg) => toast.success(msg)
// Fetch road-based route from OpenRouteService
const fetchRoute = async (start, end) => {
  const url = `https://api.openrouteservice.org/v2/directions/driving-car?api_key=${ORS_API_KEY}&start=${start[1]},${start[0]}&end=${end[1]},${end[0]}`
  const response = await fetch(url)
  const data = await response.json()

  if (data && data.features) {
    const routeCoords = data.features[0].geometry.coordinates.map((coord) => [coord[1], coord[0]])
    const distance = data.features[0].properties.segments[0].distance / 1000 // Convert meters to kilometers
    return {routeCoords, distance: distance.toFixed(2)}
  } else {
    throw new Error('Route data not found.')
  }
}

const MapUpdater = ({center, zoom}) => {
  const map = useMap()
  useEffect(() => {
    map.setView(center, zoom)
  }, [center, zoom, map])
  return null
}
//Navigations Icons Inside Map
const PanControl = () => {
  const map = useMap() // Access the map instance

  const panUp = () => map.panBy([0, -100]) // Pan 100 pixels up
  const panDown = () => map.panBy([0, 100]) // Pan 100 pixels down
  const panLeft = () => map.panBy([-100, 0]) // Pan 100 pixels left
  const panRight = () => map.panBy([100, 0]) // Pan 100 pixels right

  return (
    <div style={{position: 'absolute', top: 10, left: 10, zIndex: 1000}}>
      <button onClick={panUp} style={{display: 'block', marginLeft: '15px'}}>
        ⬆️
      </button>
      <button onClick={panLeft} style={{display: 'inline-block'}}>
        ⬅️
      </button>
      <button onClick={panRight} style={{display: 'inline-block', marginLeft: '2px'}}>
        ➡️
      </button>
      <button onClick={panDown} style={{display: 'block', marginLeft: '15px'}}>
        ⬇️
      </button>
    </div>
  )
}
//Zoom Buttons Inside Map
const ZoomControl = () => {
  const map = useMap() // Access the map instance

  const zoomIn = () => map.setZoom(map.getZoom() + 1) // Zoom in by 1 level
  const zoomOut = () => map.setZoom(map.getZoom() - 1) // Zoom out by 1 level

  return (
    <div style={{position: 'absolute', top: 10, right: 10, zIndex: 1000}}>
      <button
        onClick={zoomIn}
        style={{
          display: 'block',
          marginBottom: '5px',
          color: 'black',
          backgroundColor: 'lightBlue',
        }}
      >
        <FaSearchPlus />
      </button>
      <button
        onClick={zoomOut}
        style={{display: 'block', marginTop: '5px', color: 'black', backgroundColor: 'lightBlue'}}
      >
        <FaSearchMinus />
      </button>
    </div>
  )
}
// Mark Icon
const customIcon = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIconRetina,
  shadowUrl: markerShadow,
  iconSize: [20, 33], // Smaller than default [25, 41]
  iconAnchor: [10, 33], // Adjusted to keep the point centered
  popupAnchor: [1, -28], // Adjust popup position slightly for smaller icon
  shadowSize: [33, 33], // Make shadow proportional to icon size
})
const MapWithRoute = ({
  center,
  zoom,
  start,
  end,
  isLockedOpen,
  onMove,
  onZoomChange,
  setIsMouseMovingAndDown,
}) => {
  const [routeCoords, setRouteCoords] = useState([])
  const [distance, setDistance] = useState(null)
  const [isCtrlPressed, setIsCtrlPressed] = useState(false)

  useEffect(() => {
    fetchRoute(start, end)
      .then(({routeCoords, distance}) => {
        setRouteCoords(routeCoords)
        setDistance(distance)
      })
      .catch((error) => console.error('Error fetching route:', error))
  }, [start, end])

  // Detect if the CTRL key is pressed
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true)
      }
    }
    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const MapEventHandler = () => {
    const map = useMap()
    const [isMouseDown, setIsMouseDown] = useState(false)
    const [lastCenter, setLastCenter] = useState(map.getCenter())
    useMapEvents({
      drag: () => {
        const newCenter = map.getCenter()
        const delta = {
          lat: newCenter?.lat - lastCenter?.lat,
          lng: newCenter?.lng - lastCenter?.lng,
        }
        setLastCenter(newCenter)
        if (!isLockedOpen && !isCtrlPressed) {
          if (onMove) onMove(delta)
        }
      },
      zoom: () => {
        const newZoom = map.getZoom()
        if (!isLockedOpen && !isCtrlPressed) {
          if (onZoomChange) onZoomChange(newZoom)
        }
      },
      mousedown: () => {
        setIsMouseDown(true)
      },
      mouseup: () => {
        setIsMouseDown(false)
        setIsMouseMovingAndDown(false) // Mouse is released
      },
      mousemove: (e) => {
        if (isMouseDown) {
          setIsMouseMovingAndDown(true) // Mouse is clicked
        }
      },
    })

    return null
  }

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      style={{width: '100%', height: '100%'}}
      zoomControl={false}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapUpdater center={center} zoom={zoom} />
      {/* navigations */}
      <PanControl />
      {/* zoom Buttons */}
      {isLockedOpen && <ZoomControl />}
      {/* Start Marker */}
      <Marker position={start} icon={customIcon}>
        <Popup>Start Point</Popup>
      </Marker>
      {/* End Marker */}
      <Marker position={end} icon={customIcon}>
        <Popup>End Point</Popup>
      </Marker>
      <MapEventHandler />
      {/* Polyline to show the road route */}
      {routeCoords.length > 0 && <Polyline positions={routeCoords} color='blue' />}
    </MapContainer>
  )
}

const DraggableMaps = () => {
  const getParsedParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search)
    const paramValue = searchParams.get(param)

    try {
      // Attempt to parse only if it looks like JSON (starts with [ or {)
      return paramValue && (paramValue.startsWith('[') || paramValue.startsWith('{'))
        ? JSON.parse(paramValue)
        : paramValue
    } catch (e) {
      // Return the raw value if parsing fails
      return paramValue
    }
  }
 const screenWidth = useScreenWidth()
  const [opacity, setOpacity] = useState(getParsedParam('opacity') || 0.5)
  const [advisorURL, setAdvisorURL] = useState('')
  const [isShareURL, setIsShareURL] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [copied, setCopied] = useState(false)
  const [isOverlay, setIsOverlay] = useState(getParsedParam('isOverlay') === 'true' ? true : false)
  const [zoom, setZoom] = useState(getParsedParam('zoom') || 13)
  // State for start and end locations of New York and Lahore maps
  const [address1start, setNewYorkStart] = useState(
    getParsedParam('address1start')
      ? getParsedParam('address1start').split(',').map(Number)
      : [40.73061, -73.935242]
  )

  const [address1end, setNewYorkEnd] = useState(
    getParsedParam('address1end')
      ? getParsedParam('address1end').split(',').map(Number)
      : [40.75061, -73.975242]
  )

  const [address2start, setLahoreStart] = useState(
    getParsedParam('address2start')
      ? getParsedParam('address2start').split(',').map(Number)
      : [31.582, 74.329]
  )

  const [address2end, setLahoreEnd] = useState(
    getParsedParam('address2end')
      ? getParsedParam('address2end').split(',').map(Number)
      : [31.5546, 74.3572]
  )

  const [address1center, setNewYorkCenter] = useState(
    getParsedParam('address1center')
      ? getParsedParam('address1center').split(',').map(Number)
      : [40.73061, -73.935242]
  )

  const [address2center, setLahoreCenter] = useState(
    getParsedParam('address2center')
      ? getParsedParam('address2center').split(',').map(Number)
      : [31.5497, 74.3436]
  )

  // Input fields for city and address
  const [address1initialStart, setNewYorkStartAddress] = useState(
    getParsedParam('address1initialStart') || 'UniCredit Bank, East 42 Street'
  )
  const [address1initialEnd, setNewYorkEndAddress] = useState(
    getParsedParam('address1initialEnd') || 'Rail Road Avenue'
  )
  const [address2initialStart, setLahoreStartAddress] = useState(
    getParsedParam('address2initialStart') || 'Delhi Gate'
  )
  const [address2initialEnd, setLahoreEndAddress] = useState(
    getParsedParam('address2initialEnd') || 'Allama Iqbal Road'
  )
  const [isLockedOpen, setScrollZoomEnabled] = useState(getParsedParam('isLockedOpen') || true)

  const handleNewYorkMapMove = (delta) => {
    setLahoreCenter((prevCenter) => [prevCenter[0] + delta?.lat, prevCenter[1] + delta.lng])
  }

  const handleLahoreMapMove = (delta) => {
    setNewYorkCenter((prevCenter) => [prevCenter[0] + delta?.lat, prevCenter[1] + delta.lng])
  }
  // Handle zoom synchronization
  const handleZoom = (zoomDelta) => {
    setZoom(zoomDelta)
  }

  // sendToSheets(
  //   `${address1initialStart}, ${address1city}`,
  //   `${address1initialEnd}, ${address1city}`,
  //   `${address2initialStart}, ${address2city}`,
  //   `${address2initialEnd}, ${address2city}`,
  //   formattedDate + ' ' + formattedTime
  // )
  // sendAnalytics('Comparing Maps')

  const handleZoomIn = () => setZoom((prevZoom) => Math.min(prevZoom + 1, 18))
  const handleZoomOut = () => setZoom((prevZoom) => Math.max(prevZoom - 1, 1))
  const toggleScrollZoom = () => {
    setScrollZoomEnabled(!isLockedOpen)
  }
  const handleCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 2000) // Reset copied state after 2 seconds
  }
const ZoomControls = ({handleZoomIn, handleZoomOut}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const screenWidth = useScreenWidth()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: screenWidth<768 ? 'row' : 'column',
        flexWrap: 'wrap',
        justifyContent:screenWidth<768 ? 'center' : 'flex-start',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '20px',
      }}
    >
      <button
        onClick={() => generateImage()}
        style={{
          padding: '10px',
          color: 'black',
          fontSize: '16px',
          borderRadius: '5px',
        }}
      >
        <FaCopy />
      </button>
      <button
        onClick={handleZoomIn}
        style={{
          padding: '10px',
          color: 'black',
          fontSize: '16px',
          borderRadius: '5px',
        }}
      >
        <FaSearchPlus />
      </button>
      <button
        onClick={handleZoomOut}
        style={{
          padding: '10px',
          color: 'black',
          fontSize: '16px',
          borderRadius: '5px',
        }}
      >
        <FaSearchMinus />
      </button>
      <button
        onClick={toggleScrollZoom}
        style={{
          padding: '10px',
          color: 'black',
          fontSize: '16px',
          borderRadius: '5px',
        }}
      >
        {isLockedOpen ? <FaLockOpen /> : <FaLock />}
      </button>
      <button
        onClick={() => setIsOverlay(!isOverlay)}
        style={{
          padding: '10px',
          color: 'black',
          fontSize: '16px',
          borderRadius: '5px',
        }}
      >
        <img
          alt='chloee-img'
          className='overlay-image'
          src={toAbsoluteUrl('/media/home/overlay.png')}
        />
      </button>
      <button
        onClick={() => setIsShareURL(!isShareURL)}
        style={{
          padding: '10px',
          color: 'black',
          fontSize: '16px',
          borderRadius: '5px',
        }}
      >
        <img
          alt='chloee-img'
          className='overlay-image'
          src={toAbsoluteUrl('/media/home/share.png')}
        />
      </button>
    </div>
  )
}

  const generateImage = () => {
    // Use "map-container" if isOverlay is true, otherwise fall back to "map2"
    const chartContainer = document.getElementById(isOverlay ? 'map-container' : 'separated-maps')

    if (chartContainer) {
      domtoimage
        .toBlob(chartContainer)
        .then((blob) => {
          navigator.clipboard
            .write([
              new ClipboardItem({
                'image/png': blob,
              }),
            ])
            .then(() => {
              notifySuccess('Map image copied to clipboard')
              console.log('Map image copied to clipboard')
            })
            .catch((err) => {
              console.error('Could not copy map image to the clipboard', err)
            })
        })
        .catch((err) => {
          console.error('An error occurred capturing the map', err)
        })
    } else {
      console.error('Map container not found')
    }
  }
  useEffect(() => {
    setAdvisorURL(
      `${process.env.REACT_APP_URL}/maps?opacity=${opacity}&zoom=${zoom}&isOverlay=${isOverlay}&address1start=${address1start}&address1end=${address1end}&address2start=${address2start}&address2end=${address2end}&address1center=${address1center}&address1initialStart=${address1initialStart}&address1initialEnd=${address1initialEnd}&address2initialStart=${address2initialStart}&address2initialEnd=${address2initialEnd}&scrollZoomEnabled=${isLockedOpen}`
    )
  }, [isShareURL])
  const handleSearchForAddress1 = (startLocation, endLocation) => {
    setNewYorkStartAddress(startLocation.address)
    setNewYorkEndAddress(endLocation.address)
    setNewYorkStart([startLocation.lat, startLocation.lng])
    setNewYorkEnd([endLocation.lat, endLocation.lng])
    setNewYorkCenter([startLocation.lat, startLocation.lng])
    //  handleGoogleLocationSearch(startLocation,endLocation,setNewYorkStart,setNewYorkEnd, setNewYorkCenter)
  }
  const handleSearchForAddress2 = (startLocation, endLocation) => {
    setLahoreStartAddress(startLocation.address)
    setLahoreEndAddress(endLocation.address)
    setLahoreStart([startLocation.lat, startLocation.lng])
    setLahoreEnd([endLocation.lat, endLocation.lng])
    setLahoreCenter([startLocation.lat, startLocation.lng])
    //  handleGoogleLocationSearch(startLocation, endLocation, setLahoreStart, setLahoreEnd, setLahoreCenter)
  }

  function LocationSearchBox({handleLocationSearch, addressNumber, defaultStart, defaultEnd, searchBoxId}) {
    const [startLocation, setStartLocation] = useState({
      lat: searchBoxId === 1 ? 31.5546 : 31.582,
      lng: searchBoxId === 1 ? 74.3572 : 74.329,
      address: defaultStart,
    })
    const [endLocation, setEndLocation] = useState({
      lat: searchBoxId === 1 ? 40.75061 : 31.5546,
      lng: searchBoxId === 1 ? -73.975242 : 74.3572,
      address: defaultEnd,
    })
    return (
      <div className='location-search-box'>
        <p style={{color: 'black', marginBottom: '15px', fontWeight: 'bold', fontSize: '16px'}}>
          Address#{addressNumber}
        </p>
        {!isMouseDown ? (
          <AutocompleteField
            placeholder='From '
            onPlaceSelected={(place) => {
              setStartLocation(place)
            }}
            defaultAddress={startLocation.address}
          />
        ) : (
          <input
            type='text'
            placeholder={'To'}
            value={startLocation.address} // Controlled value
            onChange={(e) => console.log(e)} // Handle manual input changes
            className='form-control'
            style={{
              padding: '10px',
              borderRadius: '8px',
              border: '1px solid #ddd',
              marginBottom: '12px',
              width: '100%',
              boxSizing: 'border-box',
              color: 'black',
            }}
          />
        )}
        {!isMouseDown ? (
          <AutocompleteField
            placeholder='To '
            onPlaceSelected={(place) => {
              setEndLocation(place)
            }}
            defaultAddress={endLocation.address}
          />
        ) : (
          <input
            type='text'
            placeholder={'To'}
            value={endLocation.address} // Controlled value
            onChange={(e) => console.log(e)} // Handle manual input changes
            className='form-control'
            style={{
              padding: '10px',
              borderRadius: '8px',
              border: '1px solid #ddd',
              marginBottom: '12px',
              width: '100%',
              boxSizing: 'border-box',
              color: 'black',
            }}
          />
        )}
        <button
          onClick={() => handleLocationSearch(startLocation, endLocation)}
          style={{
            padding: '12px',
            borderRadius: '8px',
            border: 'none',
            backgroundColor: '#007BFF',
            color: 'white',
            fontWeight: 'bold',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          Search
        </button>
      </div>
    )
  }

  return (
    <div style={{textAlign: 'center', padding: '20px', backgroundColor: '#ba1baa', color: 'white'}}>
      <h1 style={{color: 'white'}}>What's a Mile to you?</h1>
      <p style={{color: 'white'}}>
        Ever wonder if distance 'feels the same' in other places? Now you can find out. Enter two
        locations see how they compare.
      </p>

      <div className='location-search-container'>
        <LocationSearchBox
          handleLocationSearch={handleSearchForAddress1}
          addressNumber={1}
          defaultStart={address1initialStart}
          defaultEnd={address1initialEnd}
          searchBoxId={1}
        />
        <LocationSearchBox
          handleLocationSearch={handleSearchForAddress2}
          addressNumber={2}
          defaultStart={address2initialStart}
          defaultEnd={address2initialEnd}
          searchBoxId={2}
        />
      </div>

      {!isOverlay ? (
        <>
          <div className='row'>
            {/* Zoom Controls Column */}
            <div className={`${screenWidth < 768 ? 'd-none' : 'col-1'}`}>
              {' '}
              {/* Hidden on small screens */}
              <div className='w-50'>
                <ZoomControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
              </div>
            </div>

            {/* Maps Column */}
            <div className='col-12 col-md-10'>
              <div id={isOverlay ? 'map-container' : 'separated-maps'} className='maps-container'>
                {/* Map 1 with New York Route */}
                <div className='map-item' id='map1'>
                  <MapWithRoute
                    key={`newYorkMap-${isLockedOpen}`}
                    center={address1center}
                    zoom={zoom}
                    start={address1start}
                    end={address1end}
                    isLockedOpen={isLockedOpen}
                    onMove={handleNewYorkMapMove}
                    onZoomChange={handleZoom}
                    setIsMouseMovingAndDown={setIsMouseDown}
                  />
                </div>

                {/* Map 2 with Lahore Route */}
                <div className='map-item' id='map2'>
                  <MapWithRoute
                    key={`lahoreMap-${isLockedOpen}`}
                    center={address2center}
                    zoom={zoom}
                    start={address2start}
                    end={address2end}
                    isLockedOpen={isLockedOpen}
                    onMove={handleLahoreMapMove}
                    onZoomChange={handleZoom}
                    setIsMouseMovingAndDown={setIsMouseDown}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <div className='col-1 d-none d-md-block'>
              {' '}
              {/* Hidden on small screens */}
              <div className='w-50'>
                <ZoomControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
              </div>
            </div>
            <div className={`${screenWidth <768 ?'col-12':'col-10'}`}>
              <div
                id={isOverlay ? 'map-container' : 'separated-maps'} // Only set id when isOverlay is true
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  position: 'relative',

                  ...(isOverlay ? {flexDirection: 'column', alignItems: 'center'} : {}), // Adjust layout for overlay
                }}
              >
                {/* Map 1 with New York Route */}
                <div
                  id='map1'
                  style={{
                    width: screenWidth < 768 ? '300px' : '600px',
                    height: screenWidth < 768 ? '200px' : '300px',
                    border: '1px solid black',
                    opacity: isOverlay ? 1 - opacity : 1,
                    position: isOverlay ? 'absolute' : 'relative',
                    top: isOverlay ? '50px' : '0',
                    zIndex: isOverlay ? 1 : 'auto',
                    transition: 'opacity 0.3s ease',
                    justifyContent: 'center',
                  }}
                >
                  <MapWithRoute
                    key={`newYorkMap-${isLockedOpen}`}
                    center={address1center}
                    zoom={zoom}
                    start={address1start}
                    end={address1end}
                    isLockedOpen={isLockedOpen}
                    onMove={handleNewYorkMapMove}
                    onZoomChange={handleZoom}
                    setIsMouseMovingAndDown={setIsMouseDown}
                  />
                </div>

                {/* Map 2 with Lahore Route */}
                <div
                  id='map2'
                  style={{
                    width: screenWidth < 768 ? '300px' : '600px',
                    height: screenWidth < 768 ? '200px' : '300px',
                    border: '1px solid black',
                    opacity: isOverlay ? opacity : 1,
                    position: isOverlay ? 'relative' : 'relative',
                    top: isOverlay ? '50px' : '0',
                    zIndex: isOverlay && opacity > 0.5 ? '100' : 'auto',
                    transition: 'opacity 0.3s ease',
                  }}
                >
                  <MapWithRoute
                    key={`lahoreMap-${isLockedOpen}`} // Unique key based on isLockedOpen
                    center={address2center}
                    zoom={zoom}
                    start={address2start}
                    end={address2end}
                    isLockedOpen={isLockedOpen}
                    onMove={handleLahoreMapMove}
                    onZoomChange={handleZoom}
                    setIsMouseMovingAndDown={setIsMouseDown}
                  />
                </div>
              </div>
            </div>
            {isOverlay && (
              <div style={{marginBottom: '20px', color: 'black', marginTop: '40px',}}>
                <label>Opacity: </label>
                <input
                  type='range'
                  min='0'
                  max='1'
                  step='0.1'
                  value={opacity}
                  onChange={(e) => setOpacity(e.target.value)}
                  style={{width: screenWidth < 768 ? '100px' : '300px', margin: '20px 10px', color: 'black'}}
                />
              </div>
            )}
          </div>
        </>
      )}

      <div className='hidable-zoom-controls-small-screen'>
        <ZoomControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
      </div>
      <div className='d-flex justify-content-center mt-80 '>
        {isShareURL ? (
          <>
            <div className='clipboard-container position-relative w-50'>
              <h4 className='mb-0 share-url-text'>Share URL</h4>
              <a href={advisorURL} target='_blank' rel='noreferrer'>
                {advisorURL}
              </a>
              <br />
              <br />
              <CopyToClipboard text={advisorURL} onCopy={handleCopy}>
                <div className='position-absolute clipboard-icon' onClick={handleCopy}>
                  {copied ? (
                    <p>Copied!</p>
                  ) : (
                    <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-2x' />
                  )}
                </div>
              </CopyToClipboard>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default DraggableMaps
