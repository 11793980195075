import React, {useState} from 'react'

type Option = {
  id: number
  label: string
}

interface OptionsDropdownProps {
  optionsArray: Option[] // Array of options to display
  onOptionSelect: (value: any) => void // Function to emit the selected value
}

const OptionsDropdown: React.FC<OptionsDropdownProps> = ({optionsArray, onOptionSelect}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedLabel, setSelectedLabel] = useState<string>('Select Columns')

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleSelect = (label: string) => {
    setSelectedLabel(label) // Set the selected label
    onOptionSelect(label) // Emit the selected value
    setIsOpen(false) // Close the dropdown
  }

  return (
    <div className='persona-custom-dropdown'>
      <div
        onClick={toggleDropdown}
        className='persona-dropdown-button form-select dropdown  scenario-button-color-primary'
      >
        {selectedLabel}
      </div>
      {isOpen && (
        <ul className='persona-dropdown-list'>
          {optionsArray.map((option, index) => (
            <li key={index} className='persona-dropdown-item'>
              <span
                onClick={() => handleSelect(option.label)}
                className='d-flex w-auto'
              >
                {option.label}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default OptionsDropdown
