import {useLocation} from 'react-router-dom'
import {SBLOC_SECTION, sectionIds} from '../../../../constants/AppConstants'
import SBLOCChart from './Components/SBLOCChart'
import SBLOCInputs from './Components/SBLOCInputs'
import {memo, useEffect, useState} from 'react'
import {SBLOCDataModel, SBLOCObjModel} from '../../helper/SBLOC/_model'
import FeedbackSection from '../feedbackSection/FeedbackSection'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import CopyToClipboard from 'react-copy-to-clipboard'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import {cloneDeep} from 'lodash'
import {useSBLOCProjections} from '../../utils/sblocUtils'
import {useAdvisorsState} from '../../advisor/components/AdvisorsUtils'
import {
  addSharedSbloc,
  fetchSharedSBlocDataArray,
  handleSBLOCActions,
} from '../../advisor/core/_request'
import SBLOCVerticalInputs from './Components/SBLOCVerticalInputs'
import {useSBLOCContext} from '../../../context/SBLOCContext'
import {fetchSblocClients} from '../../../../admin/modules/Clients/core/_request'

const SBLOCSection: React.FC = () => {
  let slug = ''
  const {handleCopy, copied} = useAdvisorsState()
  const {parentUserId} = useSBLOCContext()
  const {sectionIdToShowInWebViewer, showInWebViewer} = useReceiverFormContext()
  const {generateDataforEachBar} = useSBLOCProjections()
  const location = useLocation()
  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])
  const [inputData, setInputData] = useState<SBLOCObjModel>()
  const [isUndo, setIsUndo] = useState(false)
  // const [slug, setSlug] = useState('')
  const [firstRowOfTableChanged, setFirstRowOfTableChanged] = useState(false)
  const [valuesOfTableFirstRow, setvaluesOfTableFirstRow] = useState({})
  const [isShareLink, setIsShareLink] = useState('')
  const pathsToCheck = ['SBLOC=true', 'sbloc=true', 'sblocPage=true']
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]
  const pathsToCheckForSlug = ['slug']

  const {isFirstRender} = useAdvisorsState()
  const [isFaded, setIsFaded] = useState(false)
  const isSBLOCinUrl = pathsToCheck.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )

  const defaultClientConfiguration: any = {
    default: {
      clientName: 'default',
      heading: 'SBLOC Visualization',
      subHeading: 'Heard how some people "bank on themselves" indefinitely? See how.',
      description:
        'One way is with a Securities-Backed Line of Credit (SBLOC). SBLOCs let you borrow against your investments. You pay a daily interest rate on the outstanding amount borrowed. You can often then re-invest this money to other (dissimilar) assets, generating even more returns. Over time, so long as your rate of returns out-pace your borrowing costs, you can end out ahead. But, with high interest rates nowadays, borrowing can eat into your earnings – and assets. Read more about SBLOCs at',
      showSlider: false,
      font: 'helvetica',
      backGroundColor: '#ffffff',
      headingAndSliderColor: '#367045',
    },
  }

  const [clientConfigurations, setClientConfigurations] = useState<any>([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchSblocClients()
        setClientConfigurations(res.data)
      } catch (error) {
        console.error('Error fetching client configurations:', error)
      }
    }

    fetchData()
  }, [])

  const clientIdentifier = location.search.split('style=')[1] || 'default'

  const clientConfig =
    clientConfigurations.find((config: any) => config.clientName === clientIdentifier) ||
    defaultClientConfiguration.default

  // const isClientSpecificPage = location.pathname.slice(1) === `${clientIdentifier}Page`;

  const isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )
  const isSlugInUrl = pathsToCheckForSlug.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )
  const isSblocPage = location.pathname.includes('sblocPage')
  function parseCurrentUrlParameters() {
    const currentUrl = window.location.href // Get the current URL
    const urlParts = currentUrl.split('?') // Split the URL into base and query string parts

    if (urlParts.length > 1) {
      const slug = urlParts[1].split('=')[1]
      fetchSharedSBlocDataArray(slug).then((data) => {
        if (data.data.length) {
          setData(cloneDeep(data.data))
          setTableData(cloneDeep(data.data)) // Now data should be the actual value, not a Promise
        }
      })
    }
  }
  const handleBarsArrayUpdate = (barsArray: any) => {
    if (isFirstRender.current && isSlugInUrl && isSblocPage) {
      parseCurrentUrlParameters()
      isFirstRender.current = false
    } else {
      setTableData(cloneDeep(barsArray)) // Deep copy for initialData
      setData(cloneDeep(barsArray)) // Deep copy for data
    }
  }
  const handleFirstRowValues = (newValue: any, keyId: any) => {
    setFirstRowOfTableChanged(true)
    setvaluesOfTableFirstRow((prevState) => ({
      [keyId]: newValue,
    }))
    setTimeout(() => {
      setFirstRowOfTableChanged(false)
    }, 2000)
  }
  // unique slug generation
  function generateSlug() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const length = 6 // adjust the length of the slug as needed
    let slug = ''
    for (let i = 0; i < length; i++) {
      slug += characters[Math.floor(Math.random() * characters.length)]
    }
    return slug
  }
  // save sharedsbloc array to the database
  const saveArrayToDbAndShareRecord = async () => {
    slug = generateSlug()
    addSharedSbloc(JSON.stringify(data), slug)
    const searchParams = new URLSearchParams(window.location.search)
     const style = searchParams.get('style')
    // If the value of currentCompany contains additional paths, use split to extract the first segment

    setIsShareLink(
      window.location.search.includes('isNative=false')
        ? `${process.env.REACT_APP_CHLOEE_URL}/${style}/SBLOC-tool-only?slug=${slug}`
        : `${process.env.REACT_APP_URL}/sblocPage?slug=${slug}`
    )
  }
  const editYear = (values: any, data: any) => {
    data.forEach((item: any) => {
      if (
        (item.year.toString() === values.year.toString() && !values.subsequentYears) ||
        (values.subsequentYears && item.year >= values.year)
      ) {
        // Update the item properties based on values provided.
        item.starting_amount = values.startingAmount
        item.borrowed_amount = values.borrowedAmount
        item.reInvested_asset = values.reInvestedAmount
        item.APR = values.APR
        item.ROR = values.ROR
        item.reInvested_ror = values.reInvestedRor
        item.cash_flow_that_year = values.cash_flow_that_year
        item.cash_flow_frequency = values.cash_flow_frequency
      }
    })
    data = generateDataforEachBar(data)
    setTableData(cloneDeep(data))
    setData(cloneDeep(data))
  }
  const editYearForSBLOCTable = (data: any) => {
    data = generateDataforEachBar(data)
    setData(data)
    setTableData(cloneDeep(data))
    handleSBLOCActions(parentUserId)
  }
  const handleUndo = () => {
    setIsUndo(true)
    setTimeout(() => {
      setIsUndo(false)
    }, 2000)
    handleSBLOCActions(parentUserId)
  }

  const handleFormikValues = (formikValues: any) => {
    setInputData(formikValues)
    setIsShareLink('')
  }
  const handleKeyDown = (event: any) => {
    // Check if Ctrl+Z was pressed
    if (event.ctrlKey && event.key === 'z') {
      event.preventDefault() // Prevent the default action of Ctrl+Z
      handleUndo() // Call the undo handler
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [data])
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
  }, [])
  const handleClick = () => {
    saveArrayToDbAndShareRecord()
    setIsFaded(true)
    setTimeout(() => {
      setIsFaded(false)
    }, 5000) // 5000ms = 5 seconds
  }
  return (
    <>
      {isSBLOCinUrl || isSblocPage ? (
        <div
          id={sectionIds[6]}
          className={`px-7 px-lg-20 ${
            sectionIdToShowInWebViewer !== sectionIds[6] && showInWebViewer ? 'd-none' : ''
          }`}
        >
          <h1
            className={`mt-10 mb-8 text-center position-relative z-index-1`}
            style={{color: clientConfig?.color, fontFamily: clientConfig?.font}}
          >
            {clientConfig?.heading}
          </h1>
          {clientConfig.subHeading && (
            <h4 className={`my-2 text-center pl-6`} style={{fontFamily: clientConfig?.font}}>
              {clientConfig.subHeading}
            </h4>
          )}

          {clientConfig?.description && (
            <p
              className={`mx-lg-18 mx-md-0 mt-10 mb-20 text-center font-family-helvetica`}
              style={{fontFamily: clientConfig?.font}}
            >
              {`${clientConfig.description} `}
              <a href={SBLOC_SECTION.Link} target='blank'>
                Investopedia.
              </a>
            </p>
          )}

          {clientConfig?.clientName == 'default' && (
            <div className='text-select-transparent'>
              <SBLOCInputs
                isUndo={isUndo}
                onBarsArrayUpdate={handleBarsArrayUpdate}
                onChangeValues={handleFormikValues}
                IsWayStoneSlider={clientConfig.showSlider}
                sliderColor={clientConfig?.headingAndSliderColor}
                isFirstRowOfTableChanged={firstRowOfTableChanged}
                firstTableRowValue={valuesOfTableFirstRow}
                resetUndo={() => setIsUndo(false)}
                resetFirstRowOfTableChanged={() => setFirstRowOfTableChanged(false)}
                sharedSbLocInputs={
                  isFirstRender.current && isSlugInUrl && isSblocPage
                    ? data.length
                      ? Object.assign({}, data[0])
                      : {}
                    : {}
                }
              />
            </div>
          )}

          {data.length && (
            <SBLOCChart
              data={data}
              editYear={editYear}
              undo={handleUndo}
              editYearForTable={editYearForSBLOCTable}
              tableData={tableData}
              handleChangeFirstRowInputs={handleFirstRowValues}
              isWayStoneStyled={clientConfig?.clientName != 'default' ? true : false}
            />
          )}

          {/* get link button  */}

          {clientConfig?.clientName !== 'default' && (
            <div className='text-select-transparent mt-10'>
              <SBLOCVerticalInputs
                isUndo={isUndo}
                onBarsArrayUpdate={handleBarsArrayUpdate}
                onChangeValues={handleFormikValues}
                IsWayStoneSlider={clientConfig?.clientName == 'waystone' ? true : false}
                sliderColor={clientConfig?.headingAndSliderColor}
                isFirstRowOfTableChanged={firstRowOfTableChanged}
                firstTableRowValue={valuesOfTableFirstRow}
                resetUndo={() => setIsUndo(false)}
                resetFirstRowOfTableChanged={() => setFirstRowOfTableChanged(false)}
                sharedSbLocInputs={
                  isFirstRender.current && isSlugInUrl && isSblocPage
                    ? data.length
                      ? Object.assign({}, data[0])
                      : {}
                    : {}
                }
              />
            </div>
          )}
          <div className={`mt-10 mb-8 mb-md-14 text-center position-relative z-index-1`}>
            <Button
              id='getLinkButton'
              className={`custom-secondary-button mt-10 ${isFaded ? 'disabled' : ''}`}
              onClick={handleClick}
            >
              Get Link
            </Button>
          </div>
          {isShareLink && (
            <div className='clipboard-container position-relative mt-5'>
              <h3 className='mb-0 share-url-text ml-5'>Share URL</h3>
              <div className='ml-5'>
                <a href={isShareLink} target='_blank' rel='noreferrer'>
                  {isShareLink}
                </a>
              </div>
              <br />
              <CopyToClipboard text={isShareLink} onCopy={handleCopy}>
                <div
                  className='position-absolute clipboard-icon'
                  onClick={() => {
                    handleCopy()
                    handleSBLOCActions(parentUserId)
                  }}
                >
                  {copied ? (
                    <p>Copied!</p>
                  ) : (
                    <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-2x' />
                  )}
                </div>
              </CopyToClipboard>
              <i className='fas fa-times close-icon' onClick={() => setIsShareLink('')}></i>
            </div>
          )}
          <div className={showInWebViewer ? 'd-none' : ''}>
            <section className='mt-7 pe-20 pb-20 pb-8 pb-7 mb-5'>
              <FeedbackSection sectionName='SBLOC section' />
            </section>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default memo(SBLOCSection)
